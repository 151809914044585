import classNames from "classnames";
import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { socket } from "../../../../../../../../../../API/socket";
import AddIcon from "../../../../../../../../../../svgIcons/AddIcon";
import DeleteIcon from "../../../../../../../../../../svgIcons/DeleteIcon";
import ConfirmWindow from "../../../../../../../../../common/ConfirmWindow/ConfirmWindow";
import UserInfo from "../../../../../../../../../common/UserInfo/UserInfo";
import styles from './taskcardusers.module.css';

export default function TaskUserWrapper(props) {

  const [showBtn, setShowBtn] = useState(false);
  const [isOpenConfirmPopup, setIsOpenConfirmPopup] = useState(false);
  const handleClickOpenConfirm = (key) => {
    setIsOpenConfirmPopup(true)
  }

  const handleClickClosePopup = () => {
    setIsOpenConfirmPopup(false);
  }

  const handleDeleteUser = () => {
    socket.emit('DeleteTaskPerformer',
      {
        'taskId': props.taskId,
        'projectuserId': props.userId
      })
    handleClickClosePopup()
  }

  return (
    <div
      className={styles.taskUserWrapper}
      onMouseEnter={() => setShowBtn(true)}
      onMouseLeave={() => setShowBtn(false)}
    >
      <NavLink to={`/dash/${props.absoluteUserId}`}>
        <UserInfo
          avatar={props.avatar}
          first_name={props.first_name}
          last_name={props.last_name}
          email={props.email}
          isFull={true}
        />
      </NavLink>
      {
        showBtn &&
        <div className={classNames('flex', styles.btnWrap)}>
          <button
            className={classNames('flex', 'iconBtn')}
            onClick={(e) => {
              e.preventDefault()
              handleClickOpenConfirm()
            }}
          >
            <DeleteIcon />
          </button>
        </div>
      }
      {
        isOpenConfirmPopup &&
        <ConfirmWindow
          insideParent={true}
          title={'Удалить исполнителя?'}
          onDelete={handleDeleteUser}
          closeDelete={handleClickClosePopup}
        />
      }
    </div>
  )
}
