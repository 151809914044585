import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import styles from './columnstatuspopup.module.css';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { selectProjects } from "../../../../../../../../redux/projectListSelector";
import CloseIcon from "../../../../../../../../svgIcons/CloseIcon";
import { socket } from "../../../../../../../../API/socket";
import { selectColumnStatuses } from "../../../../../../../../redux/BoardPage/сolumnSelector";
import { getColumnStatuses } from "../../../../../../../../redux/BoardPage/сolumnsReducer";
import AddIcon from "../../../../../../../../svgIcons/AddIcon";
import { useOutsideClick } from "../../../../../../../hooks/useOutsideClick";


export default function ColumnStatusPopup(props) {

  const ref = useRef(null)

  useOutsideClick(ref, props.handleClickClose)

  const [color, setColor] = useState(null)
  const [colorFromInput, setColorFromInput] = useState(null)
  const [isCreatedForm, setIsCreatedForm] = useState(props.create || false)
  const [isEditStatus, setIsEditStatus] = useState(false)
  const [isShowBtn, setIsShowBtn] = useState(false)

  const dispatch = useDispatch();
  const statuses = useSelector(selectColumnStatuses);

  useEffect(() => {
    dispatch(getColumnStatuses(props.projectId));
  }, [dispatch])

  let optionsStatus = [];

  statuses?.map(item => {
    optionsStatus.push({ value: `${item.id}`, label: `${item.name}` })
  })

  const colors = [
    {
      name: '#FF0000',
    },
    {
      name: '#008000',
    },
    {
      name: '#0000FF',
    },
    {
      name: '#800080',
    },
    {
      name: '#808080',
    },
    {
      name: '#FFA500',
    },
  ]

  const {
    clearErrors,
    setError,
    getValues,
    control,
    setValue,
    register,
    reset,
    watch,
    handleSubmit,
    formState: { errors, isValid }
  } = useForm({
    mode: 'onChange',
  });

  const currentStatus = watch('status');

  useEffect(() => {
    setIsShowBtn(true)
    setIsEditStatus(false)
    setColor(statuses?.find(item => item.id === +currentStatus)?.color)
    setColorFromInput(statuses?.find(item => item.id === +currentStatus)?.color)
    setValue('name', statuses?.find(item => item.id === +currentStatus)?.name)
  }, [currentStatus, setValue])

  const addStatus = (data) => {

    socket.emit('SetColumnStatus', {
      'columnId': props.colId,
      'statusId': data.status,
    })

    props.handleClickClose();
  }
  const createStatus = (data) => {
    socket.emit('CreateStatus', {
      'columnId': props.colId,
      'name': data.name,
      'color': color
    })
    props.handleClickClose();
  }
  const deleteStatus = () => {
    socket.emit('UpdateDeleteStatus', {
      'statusId': currentStatus,
    })

    props.handleClickClose();
  }

  const editStatus = (data) => {
    socket.emit('UpdateDeleteStatus', {
      'statusId': currentStatus,
      'color': color,
      'name': data.name
    })

    props.handleClickClose();
  }

  const onSubmit = (data => {
    !isCreatedForm && !isEditStatus ?
      addStatus(data) :
      isEditStatus ?
        editStatus(data)
        :
        createStatus(data)
  })

  return (createPortal((
    <div className={classNames('flex', 'popup')}>
      <div className={classNames('popupWrapper')}>
        <div className={classNames('popupWindow', 'shadow')} ref={ref}>
          <h3 className={classNames('popupHeader')}>
            {!isCreatedForm && !isEditStatus ? 'Добавить статус' : isEditStatus ? 'Редактировать статус' : 'Создать новый статус'}
          </h3>
          <form
            className={classNames('flex', 'popupform', 'filter')}
            onSubmit={handleSubmit(onSubmit)}
            onChange={() => {
              clearErrors('serverError');
            }}
          >
            {
              !isCreatedForm &&
              <div className={classNames('flex', 'popupInputBox')}>
                <p className={classNames('popupLabel')}>Выбор статуса</p>
                <div
                  onMouseDown={e => {
                    e.stopPropagation()
                  }}
                  className={'popupInputAllView'}
                >
                  <Controller
                    control={control}
                    name='status'
                    render={({ field: { value, onChange } }) => (
                      <Select
                        isSearchable={false}
                        isClearable={true}
                        maxMenuHeight={180}
                        menuPlacement={'auto'}
                        placeholder='Выбрать'
                        classNamePrefix="react-select"
                        className={classNames('react-select-container')}
                        options={optionsStatus}
                        value={value ? optionsStatus.find((с) => с.value === value) : ''}
                        onChange={(val) => onChange(val?.value)}
                      />
                    )}
                    rules={{ required: 'Выберите статус' }}
                  />
                </div>
              </div>
            }
            {
              (currentStatus && isShowBtn) &&
              <div className={classNames('flex', 'popupBtnsWrapper', styles.editBtns)}>
                <button
                  className={classNames('btn', 'btnTransparent')}
                  type='button'
                  onClick={deleteStatus}
                >
                  Удалить из проекта
                </button>
                <button
                  className={classNames('btn', 'btnTransparent')}
                  type='button'
                  onClick={() => {
                    setIsEditStatus(true)
                    setIsShowBtn(false)
                  }}
                >
                  Редактировать
                </button>
              </div>
            }
            {
              (isCreatedForm || isEditStatus) &&
              <div className={classNames('flex', 'popupInputBox')}>
                <p className={classNames('popupLabel')}>{isEditStatus ? 'Новое название' : 'Название'}</p>
                <input
                  className={classNames('input')}
                  type='text'
                  name='name'
                  placeholder='Название'
                  {...register('name',
                    {
                      required: 'Введите название',
                      minLength: {
                        value: 5,
                        message: 'Минимум 5 символов'
                      },
                    })
                  }
                />
              </div>
            }
            {
              (isCreatedForm || isEditStatus) &&
              <div div className={classNames('flex', 'popupInputBox')}>
                <p className={classNames('popupLabel')}>Цвет текста статуса</p>
                <div className={classNames('flex', styles.colorsMenu)}>
                  <div className={classNames('flex', styles.colorsWrapper)}>
                    {
                      colors.map((elem, index) =>
                        <div
                          key={index}
                          className={styles.colorInputBox}
                        >
                          <input
                            type='radio'
                            name='radioColor'
                            value={elem.name}
                            id={elem.name + index}
                            className={classNames('iconBtn', styles.colorInput)}
                            onChange={e => {
                              setColor(e.target.value)
                              setColorFromInput(null)
                            }}
                            checked={elem.name === color}
                          >
                          </input>
                          <label
                            className={styles.colorLabel}
                            style={{ backgroundColor: `${elem.name}` }}
                            htmlFor={elem.name + index}
                          >
                          </label>
                        </div>
                      )
                    }
                  </div>
                  <div className={classNames(styles.colorInputWrapper, styles.colorInputBox)}>
                    <input
                      type='color'
                      id='inputColor'
                      className={classNames('flex', 'iconBtn', styles.colorAddBtn)}
                      onChange={e => {
                        setColorFromInput(e.target.value)
                        setColor(e.target.value)
                      }}
                      value={color}
                      register={'inputColor'}
                    />
                    <label htmlFor='inputColor'>
                      <AddIcon />
                    </label>
                  </div>
                  {
                    colorFromInput &&
                    <div
                      style={{ backgroundColor: `${colorFromInput}` }}
                      className={classNames(styles.colorFromInput, styles.colorInputBox)}
                    >
                    </div>
                  }
                </div>
              </div>
            }
            {
              (!isCreatedForm && !isEditStatus) &&
              <div className={styles.createNewWrap}>
                <button
                  onClick={() => {
                    setIsCreatedForm(true)
                    setColorFromInput(null)
                    setColor(null)
                    reset()
                  }}
                  className={classNames('btn', 'btnTransparent', styles.createNewColor)}
                  type='button'
                >
                  Создать новый
                </button>
              </div>
            }
            <div className={classNames('popupBtnsWrapper')}>
              <button
                className={'btn'}
                type='submit'
                disabled={!isValid}
              >
                {!isCreatedForm && !isEditStatus ? 'Добавить' : isEditStatus ? 'Применить' : 'Создать'}
              </button>
            </div>
          </form>
          <button
            className={classNames('closeBtn', 'iconBtn', 'flex')}
            onClick={props.handleClickClose}
          >
            <CloseIcon />
          </button>
        </div>
      </div >
    </div >), document.getElementById('modal_root'))
  )
}
