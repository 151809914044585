import React, { useEffect, useRef, useState } from 'react';
import styles from './boardcontrols.module.css';
import classNames from 'classnames';
import AddIcon from '../../../../../../svgIcons/AddIcon';
import BoardControlItem from './BoardControlItem/BoardControlItem';
import { useOutsideClick } from '../../../../../hooks/useOutsideClick';

export default function BoardControls(props) {

  const refList = useRef(null);
  useOutsideClick(refList, props.handleClickClose);

  const [color, setColor] = useState(props.currentColor || null)
  const [colorFromInput, setColorFromInput] = useState(props.currentColor || null)

  return (
    <div
      ref={refList}
      className={classNames('flex', 'not-dnd', 'menuList', 'shadow', styles.controls, (props.columnPos || props.taskPos) && styles.columnControls)}
    >
      {
        props.controlsList.filter(item => !item.hidden).map((item, index) =>
          !item.pref ?
            <BoardControlItem
              key={item.id + index}
              item={item}
              handleClickClose={props.handleClickClose}
            />
            :
            <div
              key={item.id + index}
              className={styles.prefColor}
            >
              <p>
                {item.name}
              </p>
              {
                item.pref === 'colors' &&
                <div className={classNames('flex', styles.colorsMenu)}>
                  <div className={classNames('flex', styles.colorsWrapper)}>
                    {
                      item.colorList.map((elem, index) =>
                        <div
                          key={index}
                          className={styles.colorInputBox}
                        >
                          <input
                            type='radio'
                            onChange={(e) => {
                              item.callBack(e.target.value)
                              setColor(e.target.value)
                            }}
                            className={classNames('iconBtn', styles.colorInput)}
                            style={{ backgroundColor: `${elem.name}` }}
                            name='color-col'
                            id={elem.name + index}
                            value={elem.name}
                            checked={elem.name === color}
                          >
                          </input>
                          <label
                            className={styles.colorLabel}
                            style={{ backgroundColor: `${elem.name}` }}
                            htmlFor={elem.name + index}
                          >
                          </label>
                        </div>
                      )
                    }
                  </div>
                  <div
                    className={classNames('flex', styles.myColor)}
                    onMouseDown={(e) => e.preventDefault()}
                  >
                    <div className={classNames('flex', styles.colorInputWrapper, styles.colorInputBox)}>
                      <input
                        type='color'
                        id='inputColor'
                        className={classNames(
                          styles.colorAddBtn
                        )}
                        onInput={e => {
                          // props.setChangedColor(e.target.value)
                          setColorFromInput(e.target.value)
                          setColor(e.target.value)
                        }}
                        onBlur={e => item.addColor(e.target.value)}
                        // onBlur={() => console.log(1111)}
                        onFocus={() => console.log(22222)}
                        value={color}
                      />
                      <label
                        htmlFor='inputColor'
                        className={'flex'}
                      >
                        <AddIcon />
                      </label>
                    </div>
                    {
                      colorFromInput &&
                      <div
                        style={{ backgroundColor: `${colorFromInput}` }}
                        className={classNames(styles.colorFromInput, styles.colorInputBox)}
                      >
                      </div>
                    }
                  </div>
                </div>
              }
            </div>
        )
      }
    </div>
  )
}
