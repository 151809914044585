import axios from 'axios';
import { setAuth } from '../redux/authReducer';

const objectAxios = {
  baseURL: `${process.env.REACT_APP_BASE_URL}api/`,
}

let instance = axios.create(objectAxios);

document.body.addEventListener('mousedown', (e) => {
  if (e.target.classList.contains('logBtn')) {

    if (localStorage.getItem('token')) {
      objectAxios.headers = {
        'Authorization': `Token ${localStorage.getItem('token')}`
      }
    } else {
      objectAxios.headers = {}
    }
    instance = axios.create(objectAxios);
  }
  else {
    return;
  }
})

export const authAPI = {
  login(email, password) {
    return instance.post('auth/token/login/', {
      email: email,
      password: password,
    })
  },
  invite(id) {
    return instance.get(`accept-invitation/${id}`)
  },
  me() {
    return instance.get('me/', {
      headers: {
        'Authorization': `Token ${localStorage.getItem('token')}`
      }
    })
  },
  changeMe({
    meId: meId,
    activeCompanyId: activeCompanyId,
    firstName: firstName,
    lastName: lastName,
    fatherName: fatherName,
    birthday: birthday,
    avatar: avatar,
    background_color: background_color,
    background_image: background_image,
    dark_theme: dark_theme,
  }) {
    return instance.patch('me/',
      {
        meId: meId,
        activeId: activeCompanyId,
        first_name: firstName,
        last_name: lastName,
        father_name: fatherName,
        birthday: birthday,
        avatar: avatar,
        background_color: background_color,
        background_image: background_image,
        dark_theme: dark_theme,
      },
      {
        headers: {
          'Authorization': `Token ${localStorage.getItem('token')}`,
          'Content-Type': 'multipart/form-data'
        }
      })
  },
  changePassword({
    password: password,
    newPassword: newPassword,
    confirmPassword: confirmPassword
  }) {
    return instance.post('me/change-password/',
      {
        password: password,
        newPassword: newPassword,
        confirmPassword: confirmPassword
      },
      {
        headers: {
          'Authorization': `Token ${localStorage.getItem('token')}`,
        }
      })
  },
  changeEmailStep1({
    password: password,
    new_email: new_email
  }) {
    return instance.post('me/change-email/step1/',
      {
        password: password,
        new_email: new_email
      },
      {
        headers: {
          'Authorization': `Token ${localStorage.getItem('token')}`,
        }
      })
  },
  changeEmailStep2({
    code: code,
  }) {
    return instance.post('me/change-email/step2/',
      {
        code: code,
      },
      {
        headers: {
          'Authorization': `Token ${localStorage.getItem('token')}`,
        }
      })
  },
  logout() {
    return instance.get('auth/token/logout/', {
      headers: {
        'Authorization': `Token ${localStorage.getItem('token')}`
      }
    })
  }
}

export const profileAPI = {
  getUserCalendar(userId) {
    return instance.get(`user-calendar/${userId}/`,
      {
        headers: {
          'Authorization': `Token ${localStorage.getItem('token')}`
        }
      }
    )
  },
  createUserEvent({
    userId: userId,
    date: date,
    time: time,
    name: name,
    repeat: repeat,
    end_date: end_date
  }) {
    return instance.post(`user-calendar/${userId}/`,
      {
        date: date,
        time: time,
        name: name,
        repeat: repeat,
        end_date: end_date
      },
      {
        headers: {
          'Authorization': `Token ${localStorage.getItem('token')}`
        }
      }
    )
  },
  editUserEvent({
    userId: userId,
    eventId: eventId,
    date: date,
    time: time,
    name: name,
    repeat: repeat,
    end_date: end_date
  }) {
    return instance.patch(`user-calendar/${userId}/${eventId}/`,
      {
        date: date,
        time: time,
        name: name,
        repeat: repeat,
        end_date: end_date
      },
      {
        headers: {
          'Authorization': `Token ${localStorage.getItem('token')}`
        }
      }
    )
  },
  deleteUserEvent({
    userId: userId,
    eventId: eventId,
  }) {
    return instance.delete(`user-calendar/${userId}/${eventId}/`,
      {
        headers: {
          'Authorization': `Token ${localStorage.getItem('token')}`
        }
      }
    )
  },
  getUserProfile(userId, orgId) {
    return instance.get(`user/${userId}/?org_id=${orgId}`,
      {
        headers: {
          'Authorization': `Token ${localStorage.getItem('token')}`
        }
      }
    )
  },
  getUserComments(userId, orgId) {
    return instance.get(`user/${userId}/comments/`,
      {
        headers: {
          'Authorization': `Token ${localStorage.getItem('token')}`
        }
      }
    )
  },
  createUserComments({
    userId: userId,
    author: author,
    text: text,
    display_to_customer: display_to_customer,
  }) {
    return instance.post(`user/${userId}/comments/`,
      {
        author: author,
        content: text,
        access_level: display_to_customer,
      },
      {
        headers: {
          'Authorization': `Token ${localStorage.getItem('token')}`
        }
      }
    )
  },
  editUserComment(
    {
      id: id,
      text: text,
      display_to_customer: display_to_customer,
    }
  ) {
    return instance.patch(`user/comments/${id}/`,
      {
        text: text,
        display_to_customer: display_to_customer,
      },
      {
        headers: {
          'Authorization': `Token ${localStorage.getItem('token')}`,
        }
      })
  },
  deleteUserCommentItem(id) {
    return instance.delete(`user/comments/${id}/`,
      {
        headers: {
          'Authorization': `Token ${localStorage.getItem('token')}`,
        }
      })
  },
}

export const projectsListAPI = {
  getProjectList({
    organization_ids: organization_ids
  }) {
    return instance.get(`projects/?organization_id=${organization_ids}`,
      {
        headers: {
          'Authorization': `Token ${localStorage.getItem('token')}`
        }
      }
    )
  },
  getArchivedProjectList({
    organization_ids: organization_ids
  }) {
    return instance.get(`projects/?organization_id=${organization_ids}&archived=True`,
      {
        headers: {
          'Authorization': `Token ${localStorage.getItem('token')}`,
        }
      })
  },
  duplicateProject(
    {
      id: id,
    }
  ) {
    return instance.post(`projects/duplication/`,
      {
        project_id: id
      },
      {
        headers: {
          'Authorization': `Token ${localStorage.getItem('token')}`
        }
      }
    )
  },
  createProject(
    {
      companyId: companyId,
      name: name,
      manager: manager,
      client: client,
      workers: workers,
      price: price,
      description: description,
      start_date: start_date
    }
  ) {
    return instance.post(`projects/`,
      {
        organization_id: companyId,
        name: name,
        manager: manager,
        client: client,
        workers: workers,
        price: price,
        description: description,
        attachments: [],
        start_date: start_date
      },
      {
        headers: {
          'Authorization': `Token ${localStorage.getItem('token')}`
        }
      }
    )
  },
  exportProject(
    {
      file: file,
    }
  ) {
    return instance.post(`import-project/`,
      {
        file: file,
      },
      {
        headers: {
          'Authorization': `Token ${localStorage.getItem('token')}`,
          'Content-Type': 'multipart/form-data'
        }
      }
    )
  },
  deleteProject(projectId) {
    return instance.delete(`projects/${projectId}/`, {
      headers: {
        'Authorization': `Token ${localStorage.getItem('token')}`
      }
    })
  },

  getProjectListForBoards() {
    return instance.get(`projects/accessible/`,
      {
        headers: {
          'Authorization': `Token ${localStorage.getItem('token')}`
        }
      }
    )
  },
}

export const socketFileApi = {
  setTaskFiles(files) {
    return instance.post(`task/attachment/`,
      { objects: files },
      {
        headers: {
          'Authorization': `Token ${localStorage.getItem('token')}`,
          'Content-Type': 'multipart/form-data'
        }
      }
    )
  },
  setTaskCommentFiles(files) {
    return instance.post(`task/comment/attachment/`,
      { objects: files },
      {
        headers: {
          'Authorization': `Token ${localStorage.getItem('token')}`,
          'Content-Type': 'multipart/form-data'
        }
      }
    )
  },
  setTaskBanner(id, file) {
    return instance.patch(`task/${id}/upload-image/`,
      { img: file },
      {
        headers: {
          'Authorization': `Token ${localStorage.getItem('token')}`,
          'Content-Type': 'multipart/form-data'
        }
      }
    )
  },
}

export const usersAPI = {
  getClients(organizationId) {
    return instance.get(`organization/${organizationId}/clients/`, {
      headers: {
        'Authorization': `Token ${localStorage.getItem('token')}`
      }
    })
  },
  getUsers(organizationId) {
    return instance.get(`organization/${organizationId}/users/`,
      {
        headers: {
          'Authorization': `Token ${localStorage.getItem('token')}`
        }
      })
  },
  getInvites(orgId) {
    return instance.get(`organization/${orgId}/invitation/`, {
      headers: {
        'Authorization': `Token ${localStorage.getItem('token')}`
      }
    })
  },
  editUser({
    userId: userId,
    orgId: orgId,
    hourly_rate: hourly_rate,
    monthly_rate: monthly_rate,
    position_id: position_id,
    role_id: role_id,
  }) {
    return instance.patch(`user/${userId}/?org_id=${orgId}`,
      {
        hourly_rate: hourly_rate,
        monthly_rate: monthly_rate,
        position_id: position_id,
        role_id: role_id,
      },
      {
        headers: {
          'Authorization': `Token ${localStorage.getItem('token')}`
        }
      })
  }
}

export const positionsApi = {
  getPositions(organizationId) {
    return instance.get(`organization/${organizationId}/positions/`, {
      headers: {
        'Authorization': `Token ${localStorage.getItem('token')}`
      }
    })
  },
  createPosition({
    organizationId: organizationId,
    title: title
  }) {
    return instance.post(`organization/${organizationId}/positions/`,
      {
        name: title
      },
      {
        headers: {
          'Authorization': `Token ${localStorage.getItem('token')}`
        }
      })
  },
}

export const projectItemAPI = {
  // Main project info
  getProjectInfo(projectId) {
    return instance.get(`projects/?projects_id=${projectId}`,
      {
        headers: {
          'Authorization': `Token ${localStorage.getItem('token')}`
        }
      })
  },
  editProjectInfo({
    id: id,
    name: name,
    manager: manager,
    client: client,
    workers: workers,
    price: price,
    description: description,
    start_date: start_date
  }) {
    return instance.patch(`projects/${id}/`,
      {
        name: name,
        manager: manager,
        client: client,
        workers: workers,
        price: price,
        description: description,
        start_date: start_date
      },
      {
        headers: {
          'Authorization': `Token ${localStorage.getItem('token')}`,
        }
      })
  },
  editProjectName({
    id: id,
    name: name,
  }) {
    return instance.patch(`projects/${id}/`,
      {
        name: name,
      },
      {
        headers: {
          'Authorization': `Token ${localStorage.getItem('token')}`,
        }
      })
  },
  editProjectArchived({
    id: id,
    archived: archived,
  }) {
    return instance.patch(`projects/${id}/`,
      {
        archived: archived,
      },
      {
        headers: {
          'Authorization': `Token ${localStorage.getItem('token')}`,
        }
      })
  },
  editProjectStatus({
    id: id,
    status: status,
  }) {
    return instance.patch(`projects/${id}/`,
      {
        status: status,
      },
      {
        headers: {
          'Authorization': `Token ${localStorage.getItem('token')}`,
        }
      })
  },
  editProjectTeam({
    id: id,
    team: team,
  }) {
    return instance.patch(`projects/${id}/`,
      {
        workers: team,
      },
      {
        headers: {
          'Authorization': `Token ${localStorage.getItem('token')}`,
        }
      })
  },
  editProjectFiles({
    id: id,
    files: files,
  }) {
    return instance.patch(`projects/${id}/`,
      {
        files: files,
      },
      {
        headers: {
          'Authorization': `Token ${localStorage.getItem('token')}`,
          'Content-Type': 'multipart/form-data'
        }
      })
  },

  // Projects comments
  getProjectComments(projectId) {
    return instance.get(`projects/${projectId}/comments/`, {
      headers: {
        'Authorization': `Token ${localStorage.getItem('token')}`
      }
    })
  },
  addComment(
    {
      project: project,
      author: author,
      text: text,
      display_to_customer: display_to_customer,
      files: files,
    }
  ) {
    return instance.post(`comments/`,
      {
        project: project,
        author: author,
        text: text,
        display_to_customer: display_to_customer,
        files: files,
      },
      {
        headers: {
          'Authorization': `Token ${localStorage.getItem('token')}`,
          'Content-Type': 'multipart/form-data'
        }
      })
  },
  editComment(
    {
      id: id,
      project: project,
      author: author,
      text: text,
      display_to_customer: display_to_customer,
      files: files,
    }
  ) {
    return instance.patch(`comments/${id}/`,
      {
        project: project,
        author: author,
        text: text,
        display_to_customer: display_to_customer,
        files: files,
      },
      {
        headers: {
          'Authorization': `Token ${localStorage.getItem('token')}`,
          'Content-Type': 'multipart/form-data'
        }
      })
  },
  editCommentPinned(
    {
      id: id,
      pinned: pinned,
    }
  ) {
    return instance.patch(`comments/${id}/`,
      {
        pinned: pinned,
      },
      {
        headers: {
          'Authorization': `Token ${localStorage.getItem('token')}`,
          'Content-Type': 'multipart/form-data'
        }
      })
  },
  deleteCommentItem(id) {
    return instance.delete(`comments/${id}/`,
      {
        headers: {
          'Authorization': `Token ${localStorage.getItem('token')}`,
        }
      })
  },
  filterComments(
    {
      projectId: projectId,
      date: date,
      for_all: for_all,
      hidden: hidden,
      personal: personal,
    }
  ) {
    return instance.post(`comments/filter/`,
      {
        project: projectId,
        date: date,
        for_all: for_all,
        hidden: hidden,
        personal: personal,
      },
      {
        headers: {
          'Authorization': `Token ${localStorage.getItem('token')}`
        }
      })
  },

  // Projects plan
  getProjectPlan(projectId) {
    return instance.get(`projects/${projectId}/dates/`, {
      headers: {
        'Authorization': `Token ${localStorage.getItem('token')}`
      }
    })
  },
  addPlanItem(
    {
      project: project,
      event: event,
      plan_date: plan_date,
      attribute: attribute,
      status: status,
      notification: notification,
    }
  ) {
    return instance.post(`dates/`,
      {
        project: project,
        event: event,
        plan_date: plan_date,
        attribute: attribute,
        status: status,
        notification: notification,
      },
      {
        headers: {
          'Authorization': `Token ${localStorage.getItem('token')}`
        }
      })
  },
  editPlanItem(
    {
      id: id,
      event: event,
      plan_date: plan_date,
      fact_date: fact_date,
      attribute: attribute,
      status: status,
      notification: notification,
    }
  ) {
    return instance.patch(`dates/${id}/`,
      {
        event: event,
        plan_date: plan_date,
        fact_date: fact_date,
        attribute: attribute,
        status: status,
        notification: notification,
      },
      {
        headers: {
          'Authorization': `Token ${localStorage.getItem('token')}`
        }
      })
  },
  editPlanStatus(
    {
      id: id,
      status: status,
    }
  ) {
    return instance.patch(`dates/${id}/`,
      {
        status: status,
      },
      {
        headers: {
          'Authorization': `Token ${localStorage.getItem('token')}`
        }
      })
  },
  editPlanFactDate(
    {
      id: id,
      fact_date: fact_date,
    }
  ) {
    return instance.patch(`dates/${id}/`,
      {
        fact_date: fact_date,
      },
      {
        headers: {
          'Authorization': `Token ${localStorage.getItem('token')}`
        }
      })
  },
  deletePlanItem(id) {
    return instance.delete(`dates/${id}/`,
      {
        headers: {
          'Authorization': `Token ${localStorage.getItem('token')}`
        }
      })
  },
  filterPlan(
    {
      projectId: projectId,
      plan_date: plan_date,
      fact_date: fact_date,
      attributes: attribute,
      statuses: statuses,
    }
  ) {
    return instance.post(`plans/filter/`,
      {
        project: projectId,
        plan_date: plan_date,
        fact_date: fact_date,
        attributes: attribute,
        statuses: statuses,
      },
      {
        headers: {
          'Authorization': `Token ${localStorage.getItem('token')}`
        }
      })
  },

  // Projects finance
  getProjectFinance(projectId) {
    return instance.get(`projects/${projectId}/finances/`, {
      headers: {
        'Authorization': `Token ${localStorage.getItem('token')}`
      }
    })
  },
  addFinanceItem(
    {
      project: project,
      finance_type: finance_type,
      plan_date: plan_date,
      purpose: purpose,
      amount: amount,
      comment: comment,
      files: finance_files,
      status: status,
      creator: creator
    }
  ) {
    return instance.post(`finances/`,
      {
        project: project,
        finance_type: finance_type,
        plan_date: plan_date,
        purpose: purpose,
        amount: amount,
        comment: comment,
        files: finance_files,
        status: status,
        creator: creator
      },
      {
        headers: {
          'Authorization': `Token ${localStorage.getItem('token')}`,
          'Content-Type': 'multipart/form-data'
        }
      })
  },
  editFinanceItem(
    {
      id: id,
      project: project,
      finance_type: finance_type,
      plan_date: plan_date,
      fact_date: fact_date,
      purpose: purpose,
      amount: amount,
      comment: comment,
      files: finance_files,
      status: status,
      creator: creator
    }
  ) {
    return instance.patch(`finances/${id}/`,
      {
        project: project,
        finance_type: finance_type,
        plan_date: plan_date,
        fact_date: fact_date,
        purpose: purpose,
        amount: amount,
        comment: comment,
        files: finance_files,
        status: status,
        creator: creator
      },
      {
        headers: {
          'Authorization': `Token ${localStorage.getItem('token')}`,
          'Content-Type': 'multipart/form-data'
        }
      })
  },
  editFinanceFactDate(
    {
      id: id,
      fact_date: fact_date,
    }
  ) {
    return instance.patch(`finances/${id}/`,
      {
        fact_date: fact_date,
      },
      {
        headers: {
          'Authorization': `Token ${localStorage.getItem('token')}`,
        }
      })
  },
  editFinanceStatus(
    {
      id: id,
      status: status,
    }
  ) {
    return instance.patch(`finances/${id}/`,
      {
        status: status,
      },
      {
        headers: {
          'Authorization': `Token ${localStorage.getItem('token')}`,
        }
      })
  },
  deleteFinanceItem(id) {
    return instance.delete(`finances/${id}/`,
      {
        headers: {
          'Authorization': `Token ${localStorage.getItem('token')}`,
        }
      })
  },
  filterFinance(
    {
      projectId: projectId,
      plan_date: plan_date,
      fact_date: fact_date,
      statuses: statuses,
      finance_type: finance_type,
      min_amount: min_amount,
      max_amount: max_amount,
    }
  ) {
    return instance.post(`finances/filter/`,
      {
        project: projectId,
        plan_date: plan_date,
        fact_date: fact_date,
        statuses: statuses,
        finance_type: finance_type,
        min_amount: min_amount,
        max_amount: max_amount,
      },
      {
        headers: {
          'Authorization': `Token ${localStorage.getItem('token')}`
        }
      })
  },
}

export const companyAPI = {
  getCompanyInfo(companyId) {
    return instance.get(`organization/${companyId}/`, {
      headers: {
        'Authorization': `Token ${localStorage.getItem('token')}`
      }
    })
  },
  editCompanyInfo({
    companyId: companyId,
    name: name,
    inn: inn,
    email: email,
    phone: phone,
    director: director
  }) {
    return instance.patch(`organization/${companyId}/`,
      {
        org_name: name,
        inn: inn,
        email: email,
        phone: phone,
        ceo: director
      },
      {
        headers: {
          'Authorization': `Token ${localStorage.getItem('token')}`
        }
      })
  },
  // departments
  getDepartments(companyId) {
    return instance.get(`organization/${companyId}/departments/`, {
      headers: {
        'Authorization': `Token ${localStorage.getItem('token')}`
      }
    })
  },
  createDepartment({
    companyId: companyId,
    parentDep: parentDep,
    team: team,
    name: name,
  }) {
    return instance.post(`organization/${companyId}/departments/`,
      {
        parental: parentDep,
        add_team: team,
        name: name,
      },
      {
        headers: {
          'Authorization': `Token ${localStorage.getItem('token')}`
        }
      })
  },
  editDepartment({
    companyId: companyId,
    depId: depId,
    parentDep: parentDep,
    team: team,
    name: name,
  }) {
    return instance.patch(`organization/${companyId}/departments/`,
      {
        parental: parentDep,
        add_team: team,
        name: name,
        department_id: depId
      },
      {
        headers: {
          'Authorization': `Token ${localStorage.getItem('token')}`
        }
      })
  },
  deleteDepartment(companyId, depId) {
    return instance.delete(`organization/${companyId}/departments/`,
      {
        department_id: depId
      },
      {
        headers: {
          'Authorization': `Token ${localStorage.getItem('token')}`
        }
      })
  },
  leaveCompany({
    companyId: companyId,
  }) {
    return instance.post(`organization/${companyId}/leave/`,
      {

      },
      {
        headers: {
          'Authorization': `Token ${localStorage.getItem('token')}`
        }
      })
  },
}

export const statusesAPI = {
  getProjectDateStatuses(projectId) {
    return instance.get(`projects/${projectId}/date_statuses/`, {
      headers: {
        'Authorization': `Token ${localStorage.getItem('token')}`
      }
    })
  },
  createProjectDateStatus({
    id: id,
    name: name,
    color: color
  }) {
    return instance.post(`projects/${id}/date_statuses/add/`,
      {
        name: name,
        color: color
      },
      {
        headers: {
          'Authorization': `Token ${localStorage.getItem('token')}`
        }
      })
  },
  deleteProjectDateStatus(id) {
    return instance.delete(`projects/${id}/date_statuses/`,
      {
        headers: {
          'Authorization': `Token ${localStorage.getItem('token')}`
        }
      })
  },
  editProjectDateStatus({
    id: id,
    name: name,
    color: color
  }) {
    return instance.patch(`projects/${id}/date_statuses/`,
      {
        id: id,
        name: name,
        color: color
      },
      {
        headers: {
          'Authorization': `Token ${localStorage.getItem('token')}`
        }
      })
  },

  getProjectDateAttributes(projectId) {
    return instance.get(`projects/${projectId}/date_attributes/`, {
      headers: {
        'Authorization': `Token ${localStorage.getItem('token')}`
      }
    })
  },
  createProjectDateAttribute({
    id: id,
    name: name,
    color: color
  }) {
    return instance.post(`projects/${id}/date_attributes/add/`,
      {
        name: name,
        color: color
      },
      {
        headers: {
          'Authorization': `Token ${localStorage.getItem('token')}`
        }
      })
  },
  deleteProjectDateAttribute(id) {
    return instance.delete(`projects/${id}/date_attributes/`,
      {
        headers: {
          'Authorization': `Token ${localStorage.getItem('token')}`
        }
      })
  },
  editProjectDateAttribute({
    id: id,
    name: name,
    color: color
  }) {
    return instance.patch(`projects/${id}/date_attributes/`,
      {
        id: id,
        name: name,
        color: color
      },
      {
        headers: {
          'Authorization': `Token ${localStorage.getItem('token')}`
        }
      })
  },

  getProjectStatuses(projectId) {
    return instance.get(`projects/statuses/`, {
      headers: {
        'Authorization': `Token ${localStorage.getItem('token')}`
      }
    })
  },
  createProjectStatuses({
    id: id,
    name: name,
    color: color
  }) {
    return instance.post(`projects/${id}/statuses/add/`,
      {
        name: name,
        color: color
      },
      {
        headers: {
          'Authorization': `Token ${localStorage.getItem('token')}`
        }
      })
  },
  deleteProjectStatus(id) {
    return instance.delete(`projects/${id}/statuses/`,
      {
        headers: {
          'Authorization': `Token ${localStorage.getItem('token')}`
        }
      })
  },
  editProjectStatus({
    id: id,
    name: name,
    color: color
  }) {
    return instance.patch(`projects/${id}/statuses/`,
      {
        id: id,
        name: name,
        color: color
      },
      {
        headers: {
          'Authorization': `Token ${localStorage.getItem('token')}`
        }
      })
  },
}

export const boardsListAPI = {
  getBoardsList(projId) {
    return instance.get(`project/${projId}/boards/`,
      {
        headers: {
          'Authorization': `Token ${localStorage.getItem('token')}`,
        }
      })
  },
  addBoard({
    name: name,
    project: project
  }) {
    return instance.post(`boards/`,
      {
        name: name,
        project: project
      },
      {
        headers: {
          'Authorization': `Token ${localStorage.getItem('token')}`,
        }
      })
  },
  editBoard({
    boardId: boardId,
    name: name,
    archived: archived,
  }) {
    return instance.patch(`boards/${boardId}/`,
      {
        name: name,
        archived: archived,
      },
      {
        headers: {
          'Authorization': `Token ${localStorage.getItem('token')}`
        }
      })
  },
  deleteBoard(boardId) {
    return instance.delete(`boards/${boardId}/`, {
      headers: {
        'Authorization': `Token ${localStorage.getItem('token')}`
      }
    })
  },
  duplicateBoard({
    boardId: boardId,
  }) {
    return instance.patch(`board/${boardId}/update_project/`,
      {
        boardId: boardId,
      },
      {
        headers: {
          'Authorization': `Token ${localStorage.getItem('token')}`
        }
      })
  },
  moveBoard({
    boardId: boardId,
    projectId: projectId,
  }) {
    return instance.patch(`board/${boardId}/update_project/${projectId}/`,
      {
        boardId: boardId,
        projectId: projectId,
      },
      {
        headers: {
          'Authorization': `Token ${localStorage.getItem('token')}`
        }
      })
  },
}

export const boardItemAPI = {
  getBoardLabels(id) {
    return instance.get(`board/${id}/labels/`,
      {
        headers: {
          'Authorization': `Token ${localStorage.getItem('token')}`,
        }
      })
  },
  createBoardLabel(
    {
      id: id,
      name: name,
      color: color
    }
  ) {
    return instance.post(`board/${id}/labels/`,
      {
        id: id,
        name: name,
        color: color
      },
      {
        headers: {
          'Authorization': `Token ${localStorage.getItem('token')}`,
        }
      })
  },
  editBoardLabel({
    id: id,
    name: name,
    color: color
  }) {
    return instance.patch(`board/${id}/labels/`,
      {
        id: id,
        name: name,
        color: color
      },
      {
        headers: {
          'Authorization': `Token ${localStorage.getItem('token')}`,
        }
      })
  },
  deleteBoardLabel(id) {
    return instance.delete(`board/${id}/labels/`,
      {
        headers: {
          'Authorization': `Token ${localStorage.getItem('token')}`,
        }
      })
  },

  getBoardChecklist(id) {
    return instance.get(`board/${id}/checklists/`,
      {
        headers: {
          'Authorization': `Token ${localStorage.getItem('token')}`,
        }
      })
  },

  getColumnStatuses(id) {
    return instance.get(`project/${id}/statuses/`,
      {
        headers: {
          'Authorization': `Token ${localStorage.getItem('token')}`,
        }
      })
  },
  createColumnStatus(
    {
      id: id,
      name: name,
      color: color
    }
  ) {
    return instance.post(`project/${id}/statuses/`,
      {
        id: id,
        name: name,
        color: color
      },
      {
        headers: {
          'Authorization': `Token ${localStorage.getItem('token')}`,
        }
      })
  },
  editColumnStatus({
    id: id,
    name: name,
    color: color
  }) {
    return instance.patch(`project/${id}/statuses/`,
      {
        id: id,
        name: name,
        color: color
      },
      {
        headers: {
          'Authorization': `Token ${localStorage.getItem('token')}`,
        }
      })
  },
  deleteColumnStatus(id) {
    return instance.delete(`project/${id}/statuses/`,
      {
        headers: {
          'Authorization': `Token ${localStorage.getItem('token')}`,
        }
      })
  },
}

export const tasksWithAPI = {
  getTasks(id) {
    return instance.get(`tasks/${id ? id + '/' : ''}`,
      {
        headers: {
          'Authorization': `Token ${localStorage.getItem('token')}`,
        }
      })
  },
  getTimers() {
    return instance.get(`time-entries/`,
      {
        headers: {
          'Authorization': `Token ${localStorage.getItem('token')}`,
        }
      })
  },

  createTimer(taskId) {
    return instance.post(`time-entries/`,
      {
        task_id: taskId
      },
      {
        headers: {
          'Authorization': `Token ${localStorage.getItem('token')}`,
        }
      })
  },

  stopTimer(taskId) {
    return instance.patch(`time-entries/`,
      {
        task_id: taskId
      },
      {
        headers: {
          'Authorization': `Token ${localStorage.getItem('token')}`,
        }
      })
  },

  deleteTimer(timeId) {
    return instance.delete(`time-entries/${timeId}`,
      {
        headers: {
          'Authorization': `Token ${localStorage.getItem('token')}`,
        }
      })
  },
}

export const newsAPI = {
  getNewsList({ category, page, pageSize }) {
    return instance.get(`news/news/?&page=${page}&page_size=${pageSize}${category ? `&category_id=${category}` : ''}`,
      {
        headers: {
          'Authorization': `Token ${localStorage.getItem('token')}`,
        }
      })
  },
  getCategories() {
    return instance.get(`news/categories/`,
      {
        headers: {
          'Authorization': `Token ${localStorage.getItem('token')}`,
        }
      })
  },
  getNewsItem(id) {
    return instance.get(`news/news/${id}`,
      {
        headers: {
          'Authorization': `Token ${localStorage.getItem('token')}`,
        }
      })
  },
  getPopularNews() {
    return instance.get(`news/top-viewed-news/`,
      {
        headers: {
          'Authorization': `Token ${localStorage.getItem('token')}`,
        }
      })
  },
}

export const chatAPI = {
  getChat() {
    return instance.get(`chat/message/`,
      {
        headers: {
          'Authorization': `Token ${localStorage.getItem('token')}`
        }
      }
    )
  },
  createChatMessage(
    {
      content: content,
      projectId: projectId,
      boardId: boardId,
      taskId: taskId
    }
  ) {
    return instance.post(`chat/message/`,
      {
        "content": content,
        "project_id": projectId,
        "board_id": boardId,
        "task_id": taskId
      },
      {
        headers: {
          'Authorization': `Token ${localStorage.getItem('token')}`
        }
      }
    )
  }
}

export const invite = {
  sendInvite(
    {
      email: email,
      organization_id: organization_id,
      role: role
    }
  ) {
    return instance.post(`invite-with-token/`,
      {
        emails: email,
        organization_id: organization_id,
        role: role
      },
      {
        headers: {
          'Authorization': `Token ${localStorage.getItem('token')}`
        }
      }
    )
  },
}
export const tariffAPI = {
  getTariff(
    {
      organization_id: organization_id,
    }
  ) {
    return instance.get(`accesses/${organization_id}/`,
      {
        headers: {
          'Authorization': `Token ${localStorage.getItem('token')}`
        }
      }
    )
  },
}

export function setupInterceptor(store) {
  instance.interceptors.response.use(function (response) {

    return response;
  }, function (error) {

    if (error.response?.status === 401) {
      store.dispatch(setAuth(false));
      localStorage.clear();
      sessionStorage.clear();
      return;
    }
    // else if (error.response?.status >= 400) {
    //   console.log(error.response.data)
    // }
    return Promise.reject(error);
  });
}
