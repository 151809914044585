import React, { useEffect, useRef, useState } from 'react';
import styles from './commentfilemenu.module.css';
import classNames from 'classnames';
import { useOutsideClick } from '../../../../hooks/useOutsideClick';

export default function CommentFileMenu(props) {

  const refList = useRef(null)

  useOutsideClick(refList, props.handleClickClose)

  const fileTypes = [
    {
      name: 'Фотография',
      id: 'photo',
    },
    {
      name: 'Видео',
      id: 'video',
    },
    {
      name: 'Аудио',
      id: 'audio',
    },
    {
      name: 'Файл',
      id: 'file',
    }
  ];

  return (
      <div className={classNames(styles.typesWrapper, 'menuList','shadow', 'flex')} ref={refList}>
        {
          fileTypes.map((item, index) =>
            <button
              key={index}
              onClick={() => {
                props.setType(item.id)
                props.handleClickOpenAdd()
                props.handleClickClose()
              }}
              className={classNames('iconBtn', styles.typeItem)}
            >
              {item.name}
            </button>
          )
        }
      </div>

  );
}
