import React from 'react';
import styles from './errorpage.module.css';

export default function ErrorPage(props) {

  return (
    <div className={styles.error}>
      <h1>Упс... что-то пошло не так :(</h1>
      <h2>Попробуйте позже</h2>
    </div>
  );
}
