import classNames from 'classnames';
import React, { useState } from 'react';
import { editTimer } from '../../../../../../../../../../utils/editTimer';
import styles from './timeline.module.css';
import TimelineItem from './TimelineItem/TimelineItem';

export default function Timeline(props) {

  const timeline = {
    total: 3000,
    personal: [
      {
        user: {
          id: 654654,
          first_name: "Игорь",
          last_name: "Лунтик",
          father_name: "",
          avatar_color: "purple"
        },
        time: 500
      },
      {
        user: {
          id: 654654111,
          first_name: "Валентин",
          last_name: "Спанчбоб",
          father_name: "Александрович",
          avatar_color: "green"
        },
        time: 1000
      },
      {
        user: {
          id: 65465422,
          first_name: "Кот",
          last_name: "Матроскин",
          father_name: "",
          avatar_color: "red"
        },
        time: 1500
      },
    ]
  }

  return (
    <div className={styles.wrapper}>
      <p className={styles.text}>Таймлайн:</p>
      <div className={classNames('flex', styles.timeline)}>
        {
          timeline.personal?.map((item, index) =>
            <TimelineItem
              key={item.id}
              width={Math.round((item.time / timeline.total) * 100)}
              item={item}
              last={index + 1=== timeline.personal?.length}
            />
          )
        }
      </div>
      <p className={classNames(styles.text, styles.total)}>Всего: {editTimer(timeline.total)}</p>
    </div>
  );
}
