import classNames from "classnames";
import React, { useEffect, useRef } from "react";
import styles from './deadlinepopup.module.css';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "../../../../../../../../../../svgIcons/CloseIcon";
import { socket } from "../../../../../../../../../../API/socket";
import { editDateForInput } from "../../../../../../../../../../utils/dateEditor";
import DatePick from "../../../../../../../../../common/DatePick/DatePick";
import { useOutsideClick } from "../../../../../../../../../hooks/useOutsideClick";

export default function DeadlinePopup(props) {

  const ref = useRef(null)

  useOutsideClick(ref, props.handleClickClose)

  const {
    clearErrors,
    setError,
    getValues,
    control,
    setValue,
    register,
    reset,
    handleSubmit,
    formState: { errors, isValid }
  } = useForm({
    mode: 'onChange',
  });

  const onSubmit = (data => {
    socket.emit('ChangeTaskDeadline', {
      'taskId': props.taskId,
      'deadline': data.deadline ? editDateForInput(data.deadline) : null
    })
    props.handleClickClose()
  })

  return (
    <div className={classNames('flex', 'popup')}>
      <div className={classNames('popupWrapper')}>
        <div className={classNames('popupWindow', 'shadow', styles.deadlineWrap)} ref={ref}>
          <h3 className={classNames('popupHeader')}>
            Срок задачи
          </h3>
          <form
            className={classNames('flex', 'popupform', 'filter')}
            onSubmit={handleSubmit(onSubmit)}
            onChange={() => {
              clearErrors('serverError');
            }}
          >
            <div className={classNames('flex', 'popupInputBox')}>
              <p className={classNames('popupLabel')}>Планируемая дата</p>
              <div className={'popupInputGroup'}>
                <Controller
                  control={control}
                  name='deadline'
                  render={({ field }) => (
                    <DatePick
                      handlePickDate={field.onChange}
                      startDate={props.deadline ? new Date(props.deadline) : null}
                    />
                  )}
                />
                {/* <input
                  className={classNames('input')}
                  type='date'
                  name='deadline'
                  defaultValue={props.deadline}
                  {...register('deadline')}
                /> */}
              </div>
            </div>
            <div className={classNames('popupBtnsWrapper')}>
              <button
                className={'btn'}
                type='submit'
                disabled={!isValid}
              >
                Сохранить
              </button>
            </div>
          </form>
          <button
            className={classNames('closeBtn', 'iconBtn', 'flex')}
            onClick={props.handleClickClose}
          >
            <CloseIcon />
          </button>
        </div>
      </div>
    </div >
  )
}
