import React from 'react';
import Notification from './Notification';



export default function NotificationContainer(props) {

  const notices = [
    {
      id: '1111',
      name: 'Проверьте сообщения!',
    },
    {
      id: '2222',
      name: 'Новость дня!',
    },
  ]

  return (
    <Notification
      notices={notices}
    />
  );
}
