import classNames from 'classnames';
import React, { useEffect, useRef, lazy, Suspense } from 'react';
import { generateKey } from '../../../../../utils/generateKey';
import styles from './board.module.css';
import { useState } from 'react';
import AddIcon from '../../../../../svgIcons/AddIcon';
import AddColumn from './AddColumn/AddColumn';
import { Helmet } from 'react-helmet';
import { selectProjectInfo } from '../../../../../redux/ProjectPage/projectItemSelector';
import { useDispatch, useSelector } from 'react-redux';
import { getColumnStatuses, getLabels, setBoardColumns, setColumnChange, setTaskChange, setTaskChecklist, setTaskComments } from '../../../../../redux/BoardPage/сolumnsReducer';
import { useParams } from 'react-router-dom';
import { selectColumns, selectColumnStatuses } from '../../../../../redux/BoardPage/сolumnSelector';
import { socket } from '../../../../../API/socket';
import ArchivedColumns from './ArchivedColumns/ArchivedColumns';
import { selectMe } from '../../../../../redux/authSelectors';
import { DndContext, DragOverlay, PointerSensor, TouchSensor, MouseSensor, useSensor, useSensors } from "@dnd-kit/core";
import { arrayMove, SortableContext } from '@dnd-kit/sortable';
import { useMemo } from 'react';
import Task from './Column/ColumnBody/Task/Task';
import SubTask from './Column/ColumnBody/Task/SubTask/SubTask';
import { MyPointerSensor } from '../../../../../utils/MyPointerSensor';
import Preloader from '../../../../common/Preloader/Preloader';
import { isTouchDevice } from '../../../../../utils/isTouchable';
import { getTimers } from '../../../../../redux/Tracker/timerReducer';
// import Column from './Column/Column';

const Column = lazy(() => import('./Column/Column'));

export default function Board(props) {

  const me = useSelector(selectMe)
  const dispatch = useDispatch();
  const cols = useSelector(selectColumns);
  const [answer, setAnswer] = useState(false)

  let { boardId } = useParams();

  // Подписываемся на события socket

  useEffect(() => {
    console.log(`пришли на доску ${boardId}`)

    //Устанавливаем id доски
    me?.user?.id && socket.emit('setBoardId', boardId);

    setAnswer(true)

    return () => {
      console.log(`покинули доску ${boardId}`)
    };
  }, [boardId, me?.user?.id]);

  useEffect(() => {
    socket.on('getBoard', (data) => {
      dispatch(setBoardColumns(data))
      setAnswer(false)
    })

    return () => socket.off('getBoard');
  }, [])

  useEffect(() => {
    socket.on('getChecklist', (data) => {
      dispatch(setTaskChecklist({ checklists: data.checklist, colId: data.column_id, taskId: data.task_id }))
    })

    return () => socket.off('getChecklist');
  }, [])

  useEffect(() => {
    socket.on('getComments', (data) => {
      dispatch(setTaskComments({ comments: data.comments, colId: data.column_id, taskId: data.task_id }))
    })

    return () => socket.off('getComments');
  }, [])

  useEffect(() => {
    socket.on('getColumn', (data) => {
      dispatch(setColumnChange({ column: data, colId: data.id }))
    })

    return () => socket.off('getTask');
  }, [])

  useEffect(() => {
    socket.on('getTask', (data) => {
      dispatch(setTaskChange({ task: data.task, colId: data.column_id }))
    })

    return () => socket.off('getComments');
  }, [])

  useEffect(() => {
    dispatch(getLabels(boardId))
  }, [boardId])

  useEffect(() => {
    dispatch(getTimers())
  }, [dispatch])


  const projectInfo = useSelector(selectProjectInfo);

  const [isOpenAdd, setIsOpenAdd] = useState(false);
  const [columns, setColumns] = useState(cols?.columns);

  useEffect(() => {
    setColumns(cols?.columns);
    setIsOpenAdd(cols?.columns?.length > 0 ? false : true);
  }, [cols])

  const handleOpenAddWindow = () => {
    isOpenAdd ? setIsOpenAdd(false) : setIsOpenAdd(true);
  }

  // DnD

  const [isTouch, setIsTouch] = useState(isTouchDevice())

  const [activeCol, setActiveCol] = useState(null)
  const [activeTask, setActiveTask] = useState(null)
  const [activeSubtask, setActiveSubtask] = useState(null)
  const [changeColumn, setChangeColumn] = useState([]);
  const [overColumnId, setOverColumnId] = useState(null);
  const [startColumnId, setStartColumnId] = useState(null);
  const [changeTask, setChangeTask] = useState([]);
  const [changeTaskId, setChangeTaskId] = useState([]);
  const [changeBoard, setChangeBoard] = useState([]);
  const [action, setAction] = useState(null); //значения: column, insideColumn, outsideColumn, subTask

  const findValueofItems = (id, type) => {
    if (type === 'Column') {
      return columns.find(col => col.id === id)
    }
    if (type === 'Task') {
      return columns.find(col => col.tasks.find(task => task.id === id))
    }
    if (type === 'Subtask') {
      return columns.find(item =>
        item.tasks.find(task =>
          task.subtasks.find(sub => sub.id === activeSubtask.id)))
    }
  }

  const onDragStart = (e) => {
    if (e.active.data.current.type === "Column") {
      setActiveCol(e.active.data.current.column)
      return
    }
    if (e.active.data.current.type === "Task") {
      setActiveTask(e.active.data.current.task)

      const activeCol = findValueofItems(e.active.id, "Task");
      setStartColumnId(activeCol.id)
      return
    }
    if (e.active.data.current.type === "Subtask") {
      setActiveSubtask(e.active.data.current.subtask)
      return
    }
  }

  const onDragMove = (e) => {
    const { active, over } = e;

    if (!over) setAction(null)

    if (active.data.current.type === "Task" && over?.data.current.type === "Task") {
      if (active.id === over.id) {
        return
      }
      const activeCol = findValueofItems(active.id, "Task");
      const overCol = findValueofItems(over.id, "Task");

      if (!activeCol || !overCol) return

      const activeColIndex = columns.findIndex(
        col => col.id === activeCol.id
      )
      const overColIndex = columns.findIndex(
        col => col.id === overCol.id
      )

      const activeTaskIndex = activeCol.tasks.findIndex(task => task.id === active.id)
      const overTaskIndex = overCol.tasks.findIndex(task => task.id === over.id)

      if (activeColIndex === overColIndex) {
        let newItems = [...columns];

        newItems[activeColIndex].tasks = arrayMove(newItems[activeColIndex].tasks,
          activeTaskIndex,
          overTaskIndex)

        startColumnId === overCol.id && setAction('insideColumn');
        setChangeColumn(newItems[activeColIndex].tasks.map(item => item.id));
        setOverColumnId(overCol.id)

        setColumns(newItems)
      } else {
        let newItems = [...columns];

        const [removedItem] = newItems[activeColIndex].tasks.splice(
          activeTaskIndex, 1
        )

        newItems[overColIndex].tasks.splice(
          overTaskIndex,
          0,
          removedItem
        )

        startColumnId !== overCol.id && setAction('outsideColumn')
        setChangeColumn(newItems[overColIndex].tasks.map(item => item.id))
        setOverColumnId(overCol.id)

        setColumns(newItems)
      }
    }

    if (
      active.data.current.type === "Task" &&
      over?.data.current.type === "Column" &&
      active &&
      over &&
      active.id !== over.id
    ) {
      let newItems = [...columns];

      const activeCol = findValueofItems(active.id, "Task");
      const overCol = findValueofItems(over.id, "Column");

      if (!activeCol || !overCol) return;

      const activeColIndex = columns.findIndex(
        col => col.id === activeCol.id
      )
      const overColIndex = columns.findIndex(
        col => col.id === overCol.id
      )

      const activeTaskIndex = activeCol.tasks.findIndex(task => task.id === active.id)
      const [removedItem] = newItems[activeColIndex].tasks.splice(
        activeTaskIndex, 1
      )

      newItems[overColIndex].tasks.push(removedItem)

      startColumnId !== overCol.id && setAction('outsideColumn')
      setChangeColumn(newItems[overColIndex].tasks.map(item => item.id))
      setOverColumnId(overCol.id)

      setColumns(newItems)
    }

    if (active.data.current.type === "Subtask" &&
      over?.data.current.type === "Subtask"
    ) {

      setAction('subTask')

      if (active.id === over.id) {
        return
      }

      const activeCol = findValueofItems(active.id, "Subtask");
      const overCol = findValueofItems(over.id, "Subtask");

      if (!activeCol || !overCol) return

      const activeColIndex = columns.findIndex(
        col => col.id === activeCol.id
      )
      const overColIndex = columns.findIndex(
        col => col.id === overCol.id
      )

      const activeTask = activeCol.tasks.find(task => task.subtasks.find(sub => sub.id === activeSubtask.id));
      const overTask = overCol.tasks.find(task => task.subtasks.find(sub => sub.id === activeSubtask.id));

      if (!activeTask || !overTask) return

      const activeTaskIndex = activeCol.tasks.findIndex(task => task.id === activeTask.id);
      const overTaskIndex = overCol.tasks.findIndex(task => task.id === overTask.id);

      const activeSubtaskIndex = activeTask.subtasks.findIndex(sub => sub.id === active.id)
      const overSubtaskIndex = overTask.subtasks.findIndex(sub => sub.id === over.id)

      if (activeColIndex === overColIndex && activeTaskIndex === overTaskIndex) {
        let newItems = [...columns];

        newItems[activeColIndex].tasks[activeTaskIndex].subtasks = arrayMove(newItems[activeColIndex].tasks[activeTaskIndex].subtasks,
          activeSubtaskIndex,
          overSubtaskIndex)

        setChangeTaskId(activeTask.id)
        setChangeTask(newItems[activeColIndex].tasks[activeTaskIndex].subtasks.map(item => item.id))
        setColumns(newItems)
      } else {
        return
      }
    }

    if (active.data.current.type === "Column" &&
      over?.data.current.type === "Column"
    ) {
      setAction('column')

      const activeCol = findValueofItems(active.id, "Column");
      const overCol = findValueofItems(over.id, "Column");
      const activeColIndex = columns.findIndex(item => item.id === activeCol.id);
      const overColIndex = columns.findIndex(item => item.id === overCol.id);

      if (!activeCol || !overCol) {
        return
      } else if (activeCol.id === overCol.id) {
        return
      } else {
        let newItems = [...columns];

        newItems = arrayMove(newItems, activeColIndex, overColIndex)
        setChangeBoard(newItems.map(item => item.id))
        setColumns(newItems)
      }
    }
  }

  const onDragEnd = e => {
    const { active, over } = e;

    if (action === 'insideColumn') {
      socket.emit('UpdateTaskOrder', {
        "columnId": overColumnId,
        "taskIds": changeColumn
      })
    } else if (action === 'outsideColumn') {
      socket.emit('UpdateTaskColumn', {
        "taskId": active.id,
        "columnId": overColumnId,
        "taskIds": changeColumn
      })
    } else if (action === 'subTask') {
      socket.emit('UpdateSubtaskOrder', {
        "taskId": changeTaskId,
        "subtaskIds": changeTask
      })
    } else if (action === 'column') {
      socket.emit('UpdateColumnOrder', {
        "boardId": boardId,
        "columnsIds": changeBoard
      })
    }

    setActiveCol(null)
    setActiveTask(null)
    setActiveSubtask(null)
    setChangeColumn([])
    setAction(null)
    setOverColumnId(null)
    setStartColumnId(null)
    setChangeTaskId(null)
  }

  const sensors = useSensors(
    useSensor(MyPointerSensor, {
      activationConstraint:
        !isTouch ?
          {
            distance: 3,
          }
          :
          {
            delay: 250,
            tolerance: 5,
          },
    }),
  )

  const columnIds = useMemo(() => {
    return columns?.map(item => item?.id)
  }, [columns])

  return (

    <>
      <Helmet>
        <title>{`Доска - ${projectInfo?.name} - Boom`}</title>
      </Helmet>
      {
        !answer ?
          <DndContext
            sensors={sensors}
            onDragStart={onDragStart}
            onDragEnd={onDragEnd}
            onDragMove={onDragMove}
          >
            <div
              className={classNames(styles.board)}
            >
              <SortableContext items={columnIds || []}>
                {columns?.filter(item => !item.archived)?.map(item =>
                  <Column
                    key={item.id}
                    column={item}
                    boardId={boardId}
                  />
                )}
              </SortableContext>
              {
                isOpenAdd &&
                <AddColumn
                  boardId={boardId}
                />
              }
              <button
                onClick={handleOpenAddWindow}
                className={classNames('flex', styles.addBtn, isOpenAdd && styles.btnActive)}
              >
                <AddIcon />
              </button>
              {
                columns?.filter(item => item.archived)?.length > 0 &&
                <ArchivedColumns
                  archived={columns?.filter(item => item.archived)}
                />
              }
            </div>

            <DragOverlay adjustScale={false}>
              {
                activeCol &&
                <Column
                  column={activeCol}
                />
              }
              {
                activeTask &&
                <Task
                  task={activeTask}
                  col={columns.find(item => item.tasks.find(task => task.id === activeTask.id))}
                />
              }
              {
                activeSubtask &&
                <SubTask
                  item={activeSubtask}
                  overlay={true}

                  taskId={columns.find(item => item.tasks.find(task => task.subtasks.find(sub => sub.id === activeSubtask.id))).tasks.find(task => task.subtasks.find(sub => sub.id === activeSubtask.id)).id}
                  color={columns.find(item => item.tasks.find(task => task.subtasks.find(sub => sub.id === activeSubtask.id)))?.color?.color}
                  task={columns.find(item => item.tasks.find(task => task.subtasks.find(sub => sub.id === activeSubtask.id))).tasks.find(task => task.subtasks.find(sub => sub.id === activeSubtask.id))}
                />
              }
            </DragOverlay>
          </DndContext>
          :
          <Preloader />
      }
    </>
  );
}
