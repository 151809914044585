import React from 'react';
import styles from './notice.module.css';
import classNames from 'classnames';

export default function Notice(props) {

  return (
    <div>
      {props.item.name}
    </div>
  );
}
