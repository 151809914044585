import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import styles from './taskmovepopup.module.css';
import { Controller, useForm } from 'react-hook-form';
import Select from "react-select";
import CloseIcon from "../../../../../../../../../../svgIcons/CloseIcon";
import { socket } from "../../../../../../../../../../API/socket";
import { useOutsideClick } from "../../../../../../../../../hooks/useOutsideClick";

export default function TaskMovePopup(props) {
  const ref = useRef(null)

  useOutsideClick(ref, props.handleClickClose)

  const [optionsBoards, setOptionsBoards] = useState([])
  const [optionsColumns, setOptionsColumns] = useState([])
  const [activeProject, setActiveProject] = useState({})

  let optionsProjects = [];
  props.projects?.filter(item => !item.archived).map((item) => {
    optionsProjects.push({
      value: `${item?.id}`, label: `${item?.name}`
    })
  })

  const {
    clearErrors,
    setError,
    getValues,
    control,
    setValue,
    register,
    reset,
    resetField,
    watch,
    handleSubmit,
    formState: { errors, isValid }
  } = useForm({
    mode: 'onChange',
  });

  const project = watch('project');
  const board = watch('board');

  useEffect(() => {
    resetField('board');
    resetField('column');

    const active = props.projects?.find(item => +item.id === +project)

    setActiveProject(active)

    active && setOptionsBoards(
      active?.task_boards.filter(item => !item.archived).map((item) => {
        return { value: `${item?.id}`, label: `${item?.name}` }
      })
    )
  }, [project])

  useEffect(() => {
    resetField('column');

    const activeBoard = activeProject?.task_boards?.find(item => +item.id === +board)

    activeBoard && setOptionsColumns(
      activeBoard.columns?.filter(item => !item.archived).map((item) => {
        return { value: `${item?.id}`, label: `${item?.name}` }
      })
    )
  }, [board, activeProject])

  const moveTask = (data) => {
    socket.emit('CopyTask',
      {
        "taskId": props.taskId,
        "columnId": data.column,
        "action": "move",
      })
    props.handleClickClose()
  }

  const copyTask = (data) => {
    socket.emit('CopyTask',
      {
        "taskId": props.taskId,
        "columnId": data.column,
        "action": "copy",
        "checklist": data.checkList,
        "comments": data.comments,
        "files": data.additionals,
        "subtasks": data.subtasks,
        "NewName": data.name
      })


    props.handleClickClose()
  }

  const onSubmit = (data => {
    props.keyAct === 'move'
      ?
      moveTask(data)
      :
      copyTask(data)
  })

  return (
    <div className={classNames('flex', 'popup')}>
      <div className={classNames('popupWrapper')}>
        <div className={classNames('popupWindow', 'shadow')} ref={ref}>
          <h3 className={classNames('popupHeader')}>
            {props.keyAct === 'move' ? 'Перемещение задачи' : 'Копирование задачи'}
          </h3>
          <form
            className={classNames('flex', 'popupform', 'filter')}
            onSubmit={handleSubmit(onSubmit)}
            onChange={() => {
              clearErrors('serverError');
            }}
          >
            <div className={classNames('flex', 'popupInputBox')}>
              <p className={classNames('popupLabel')}>Выбор проекта</p>
              <div
                onMouseDown={e => {
                  e.stopPropagation()
                }}
                className={'popupInputAllView'}
              >
                <Controller
                  control={control}
                  name='project'
                  render={({ field: { value, onChange } }) => (
                    <Select
                      isSearchable={false}
                      isClearable={true}
                      maxMenuHeight={180}
                      menuPlacement={'auto'}
                      placeholder='Выбрать'
                      classNamePrefix="react-select"
                      className={classNames('react-select-container')}
                      options={optionsProjects}
                      value={value ? optionsProjects.find((с) => с.value === value) : ''}
                      onChange={(val) => onChange(val?.value)}
                    />
                  )}
                  rules={{ required: 'Выберите проект' }}
                />
              </div>
            </div>
            <div className={classNames('flex', 'popupInputBox')}>
              <p className={classNames('popupLabel')}>Выбор доски</p>
              <div
                onMouseDown={e => {
                  e.stopPropagation()
                }}
                className={'popupInputAllView'}
              >
                <Controller
                  control={control}
                  name='board'
                  render={({ field: { value, onChange } }) => (
                    <Select
                      isSearchable={false}
                      isClearable={true}
                      maxMenuHeight={180}
                      menuPlacement={'auto'}
                      placeholder='Выбрать'
                      classNamePrefix="react-select"
                      className={classNames('react-select-container')}
                      options={optionsBoards}
                      value={value ? optionsBoards.find((с) => с.value === value) : ''}
                      onChange={(val) => onChange(val?.value)}
                    />
                  )}
                  rules={{ required: 'Выберите доску' }}
                />
              </div>
            </div>
            <div className={classNames('flex', 'popupInputBox')}>
              <p className={classNames('popupLabel')}>Выбор колонки</p>
              <div
                onMouseDown={e => {
                  e.stopPropagation()
                }}
                className={'popupInputAllView'}
              >
                <Controller
                  control={control}
                  name='column'
                  render={({ field: { value, onChange } }) => (
                    <Select
                      isSearchable={false}
                      isClearable={true}
                      maxMenuHeight={180}
                      menuPlacement={'auto'}
                      placeholder='Выбрать'
                      classNamePrefix="react-select"
                      className={classNames('react-select-container')}
                      options={optionsColumns}
                      value={value ? optionsColumns.find((с) => с.value === value) : ''}
                      onChange={(val) => onChange(val?.value)}
                    />
                  )}
                  rules={{ required: 'Выберите колонку' }}
                />
              </div>
            </div>
            {
              props.keyAct === 'copy' &&
              <div className={classNames('flex', 'popupInputBox')}>
                <input
                  className={classNames('input')}
                  type='text'
                  name='name'
                  defaultValue={props.taskName + ' (копия)'}
                  placeholder='Новое название'
                  {...register('name',
                    {
                      required: 'Введите название',
                      minLength: {
                        value: 5,
                        message: 'Минимум 5 символов'
                      },
                    })}
                />
              </div>
            }
            {
              (props.taskCheck && props.keyAct === 'copy') &&
              <div
                className={classNames('flex', 'checkboxContainer')}
              >
                <input
                  type="checkbox"
                  id='check'
                  name='checkList'
                  {...register('checkList')}
                />
                <label htmlFor='check'>Чек-лист</label>
              </div>
            }
            {
              (props.taskAddit.length > 0 && props.keyAct === 'copy') &&
              <div
                className={classNames('flex', 'checkboxContainer')}
              >
                <input
                  type="checkbox"
                  id='additionals'
                  name='additionals'
                  {...register('additionals')}
                />
                <label htmlFor='additionals'>Вложения</label>
              </div>
            }
            {
              (props.taskComm.length > 0 && props.keyAct === 'copy') &&
              <div
                className={classNames('flex', 'checkboxContainer')}
              >
                <input
                  type="checkbox"
                  id='comments'
                  name='comments'
                  {...register('comments')}
                />
                <label htmlFor='comments'>Комментарии</label>
              </div>
            }
            {
              (props.subtasks.length > 0 && props.keyAct === 'copy') &&
              <div
                className={classNames('flex', 'checkboxContainer')}
              >
                <input
                  type="checkbox"
                  id='subtasks'
                  name='subtasks'
                  {...register('subtasks')}
                />
                <label htmlFor='subtasks'>Подзадачи</label>
              </div>
            }
            <div className={classNames('popupBtnsWrapper')}>
              <button
                className={'btn'}
                type='submit'
                disabled={!isValid}
              >
                {props.keyAct === 'move' ? 'Переместить' : 'Копировать'}
              </button>
            </div>
          </form>
          <button
            className={classNames('closeBtn', 'iconBtn', 'flex')}
            onClick={props.handleClickClose}
          >
            <CloseIcon />
          </button>
        </div>
      </div>
    </div >
  )
}
