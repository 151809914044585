import React, { useState } from 'react';
import styles from './taskhistory.module.css';
import classNames from 'classnames';
import { editDateToFull, editTime } from '../../../../../../../../../../utils/dateEditor';

export default function TaskHistory(props) {

  return (
    <div>
      <div className={classNames('flex', 'blockHeader')}>
      </div>
      <div className={classNames('flex', styles.list)}>
        {props.history.map(item =>
          <div>
            <div className={styles.grey}>{editDateToFull(item.time)} {editTime(item.time)}</div>
            <div>
              <span className={styles.user}>{item.user}</span> {item.event}
            </div>
            <div className={styles.grey}>
              {item.path}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
