import React from 'react';
import classNames from 'classnames';

export default function PageHeader({children}) {

  return (
    <div className={classNames('flex', 'pageHeader')}>
      {children}
    </div>
  );
}
