import { useEffect } from 'react';

export const useOutsideClick = (ref, handleClickClose) => {

  const outsideHandleClick = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      handleClickClose(false);
    }
  }

  useEffect(() => {
    document.addEventListener('mouseup', outsideHandleClick);
    return () => {document.removeEventListener('mouseup', outsideHandleClick)}
  }, [ref, handleClickClose])
}
