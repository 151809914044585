import React, { useState, useEffect, useRef } from 'react';
import styles from './editor.module.css';
import ReactQuill, { Quill } from 'react-quill';

export default function Editor(props) {

  const modules = {
    toolbar: [
      [{ 'header': [1, 2, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
      ['link', 'image'],
      [{ color: [] }, { background: [] }],
    ],
  }

  const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image',
    'color', 'background',
  ]

  return (
    <div
      onFocus={props.inTask && props.showEditor}
      onBlur={props.inTask && props.showEditor}
    >
      <ReactQuill
        ref={props.reactQuillRef}
        theme={'snow'}
        onChange={props.handleChange}
        modules={modules}
        formats={formats}
        value={props.editorHtml}
        placeholder={props.placeholder || "Введите текст"}
        onKeyDown={(e) => {
          props.handleKeyDown(e)
        }}
      />
    </div>
  );
};
