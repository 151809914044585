import classNames from 'classnames';
import React, { useState } from 'react';
import { editTimer } from '../../../../../../../../../../../utils/editTimer';
import { editName } from '../../../../../../../../../../../utils/nameEditor';
import styles from './timelineitem.module.css';

export default function TimelineItem(props) {

  const [showUser, setShowUser] = useState(false)

  return (
    <>

      <div
        onMouseEnter={() => setShowUser(true)}
        onMouseLeave={() => setShowUser(false)}
        style={{
          width: `${props.width}%`,
          backgroundColor: `${props.item?.user?.avatar_color}`
        }}
        className={classNames(styles.item, props.last && styles.last)}
      >
        {
          showUser &&
          <span
            className={styles.triangle}
          >
          </span>
        }
      </div>
      {
        showUser &&
        <div
          className={classNames('contentBlock', styles.user)}
        >
          {props.item?.user?.last_name} {editName(props.item?.user?.first_name)} {editName(props.item?.user?.father_name)}
          <p className={classNames('flex', styles.time)}>
            <span
              style={{
                backgroundColor: `${props.item?.user?.avatar_color}`
              }}
              className={styles.dot}
            ></span>
            Время: {editTimer(props.item?.time)}
          </p>
          <p className={classNames(styles.time)}>
            {props.width}%
          </p>
        </div>
      }
    </>
  );
}
