import React from 'react';

export default function NotificationIcon(props) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10 5a2 2 0 1 1 4 0a7 7 0 0 1 4 6v3a4 4 0 0 0 2 3h-16a4 4 0 0 0 2 -3v-3a7 7 0 0 1 4 -6" stroke="#3E5196" />
      <path d="M9 17v1a3 3 0 0 0 6 0v-1" stroke="#3E5196" />
    </svg>
  );
}
