import React, { useRef } from 'react';
import styles from './sidebar.module.css';
import classNames from 'classnames';
import UserControlsContainer from './UserControls/UserControlsContainer';
import { useState } from 'react';
import NavigationContainer from './Navigation/NavigationContainer';
import { useEffect } from 'react';

export default function Sidebar(props) {

  const [isFull, setIsFull] = useState(sessionStorage.getItem('isOpenMenu') ? JSON.parse(sessionStorage.getItem('isOpenMenu')) : true);
  const handleOpen = () => {
    isFull ? setIsFull(false) : setIsFull(true);
    sessionStorage.setItem('isOpenMenu', isFull ? false : true);
  }

  const refMenu = useRef(null)

  useEffect(() => {
    // setInterval(() => {
      props.setMenuWidth(refMenu?.current?.getBoundingClientRect().width)
    // }, 10)
    return () => { }
  }, [refMenu, isFull, props])

  return (
    <div
      ref={refMenu}
      className={classNames('flex', styles.sidebar, isFull && styles.fullNav)}
    >
      <UserControlsContainer
        width={props.width}
        isFull={isFull}
      />
      <NavigationContainer
        isFull={isFull}
        width={props.width}
        handleOpen={handleOpen}
        menuWidth={props.menuWidth}
      />
      {/* <div className={styles.version}>
        v1.0.1
      </div> */}
    </div>
  );
}
