import classNames from "classnames";
import React, { useEffect, useRef } from "react";
import styles from './taskduplicatepopup.module.css';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { selectProjects } from "../../../../../../../../../../redux/projectListSelector";
import CloseIcon from "../../../../../../../../../../svgIcons/CloseIcon";
import { socket } from "../../../../../../../../../../API/socket";
import { useOutsideClick } from "../../../../../../../../../hooks/useOutsideClick";

export default function TaskDuplicatePopup(props) {

  const ref = useRef(null)

  useOutsideClick(ref, props.handleClickClose)

  const dispatch = useDispatch()
  const projects = useSelector(selectProjects);

  const {
    clearErrors,
    setError,
    getValues,
    control,
    setValue,
    register,
    reset,
    handleSubmit,
    formState: { errors, isValid }
  } = useForm({
    mode: 'onChange',
  });

  const onSubmit = (data => {
    socket.emit('CopyTask',
      {
        "taskId": props.taskId,
        "columnId": props.colId,
        "action": "duplicate",
        "checklist": data.checkList,
        "comments": data.comments,
        "files": data.additionals,
        "subtasks": data.subtasks,
        "NewName": data.name
      })
    props.handleClickClose()
  })

  return (
    <div className={classNames('flex', 'popup')}>
      <div className={classNames('popupWrapper')}>
        <div className={classNames('popupWindow', 'shadow')} ref={ref}>
          <h3 className={classNames('popupHeader')}>
            Дублировать задачу
          </h3>
          <form
            className={classNames('flex', 'popupform', 'filter')}
            onSubmit={handleSubmit(onSubmit)}
            onChange={() => {
              clearErrors('serverError');
            }}
          >
            <div className={classNames('flex', 'popupInputBox')}>
              <input
                className={classNames('input')}
                type='text'
                name='name'
                defaultValue={props.taskName + ' (копия)'}
                placeholder='Новое название'
                {...register('name',
                  {
                    required: 'Введите название',
                    minLength: {
                      value: 5,
                      message: 'Минимум 5 символов'
                    },
                  })}
              />
            </div>
            {
              props.taskCheck &&
              <div
                className={classNames('flex', 'checkboxContainer')}
              >
                <input
                  type="checkbox"
                  id='check'
                  name='checkList'
                  {...register('checkList')}
                />
                <label htmlFor='check'>Чек-лист</label>
              </div>
            }
            {
              props.taskAddit.length > 0 &&
              <div
                className={classNames('flex', 'checkboxContainer')}
              >
                <input
                  type="checkbox"
                  id='additionals'
                  name='additionals'
                  {...register('additionals')}
                />
                <label htmlFor='additionals'>Вложения</label>
              </div>
            }
            {
              props.taskComm.length > 0 &&
              <div
                className={classNames('flex', 'checkboxContainer')}
              >
                <input
                  type="checkbox"
                  id='comments'
                  name='comments'
                  {...register('comments')}
                />
                <label htmlFor='comments'>Комментарии</label>
              </div>
            }
            {
              props.subtasks.length > 0 &&
              <div
                className={classNames('flex', 'checkboxContainer')}
              >
                <input
                  type="checkbox"
                  id='subtasks'
                  name='subtasks'
                  {...register('subtasks')}
                />
                <label htmlFor='subtasks'>Подзадачи</label>
              </div>
            }
            <div className={classNames('popupBtnsWrapper')}>
              <button
                className={'btn'}
                type='submit'
                disabled={!isValid}
              >
                Создать задачу
              </button>
            </div>
          </form>
          <button
            className={classNames('closeBtn', 'iconBtn', 'flex')}
            onClick={props.handleClickClose}
          >
            <CloseIcon />
          </button>
        </div>
      </div>
    </div >
  )
}
