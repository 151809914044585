import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { socket } from '../../../../../../../API/socket';
import { selectColumnStatuses } from '../../../../../../../redux/BoardPage/сolumnSelector';
import { getColumnStatuses } from '../../../../../../../redux/BoardPage/сolumnsReducer';
import { selectProjectInfo } from '../../../../../../../redux/ProjectPage/projectItemSelector';
import DeleteIcon from '../../../../../../../svgIcons/DeleteIcon';
import EditIcon from '../../../../../../../svgIcons/EditIcon';
import { hex2rgb } from '../../../../../../../utils/hex2rgb';
import StatusList from '../../../../../../common/StatusList/StatusList';
import ColumnStatusPopup from '../ColumnPopups/ColumnStatusPopup/ColumnStatusPopup';
import AddTaskForm from './AddTaskForm/AddTaskForm';
import ArchivedColsControls from './ArchivedColsControls/ArchivedColsControls';
import ColumnControls from './ColumnControls/ColumnControls';
import styles from './columnheader.module.css';

export default function ColumnHeader(props) {

  const dispatch = useDispatch();
  const statusList = useSelector(selectColumnStatuses);
  const projectInfo = useSelector(selectProjectInfo);

  const [isOpenAddTask, setIsOpenAddTask] = useState(!props.tasks?.length || false);
  const [focusTask, setFocusTask] = useState(false)
  const [taskDescr, setTaskDescr] = useState('');
  const [isEditName, setIsEditName] = useState(false);
  const [changeText, setChangeText] = useState(props.title);
  const [controlsStatus, setControlsStatus] = useState(false);
  const [isOpenStatusPopup, setIsOpenStatusPopup] = useState(false);

  const [isOpenList, setIsOpenList] = useState(false);
  const handleClickOpenList = () => {
    dispatch(getColumnStatuses(projectInfo.id))
    setIsOpenList(true)
  }

  useEffect(() => {
    if (props.tasks?.length === 0) {
      setIsOpenAddTask(true)
    }
  }, [props.tasks])

  useEffect(() => {
    setFocusTask(false)
  }, [])

  const clearForm = () => {
    setTaskDescr('');
    setIsOpenAddTask(false);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    socket.emit('addTask',
      {
        columnId: props.colId,
        name: taskDescr,
      }
    )
    clearForm()
  }

  const handleRename = (e) => {
    if (changeText.length > 0) {
      socket.emit('RenameColumn',
        {
          columnId: props.colId,
          name: changeText,
        }
      )
    } else {
      return
    }
  }

  const handleDeleteStatus = () => {
    socket.emit('SetColumnStatus', {
      'columnId': props.colId,
    })
  }

  const handleChangeStatus = (id) => {
    socket.emit('SetColumnStatus', {
      'columnId': props.colId,
      'statusId': id,
    })
  }

  const handleClickOpenStatus = () => {
    setIsOpenList(false)
    setIsOpenStatusPopup(true);
    document.body.classList.add('modal-show');
  }

  const handleClickClosePopup = () => {
    setIsOpenStatusPopup(false);
    document.body.classList.remove('modal-show');
  }

  return (
    <>
      <div
        style={{ backgroundColor: props.color }}
        className={classNames('flex', 'contentBlock', styles.columnHeader)}
      >
        {props.state &&
          <div
            className={styles.columnState}
            style={{
              backgroundColor: controlsStatus ? `${props.state.color}` : 'transparent'
            }}
            // onMouseEnter={() => setControlsStatus(true)}
            // onMouseLeave={() => setControlsStatus(false)}
            onClick={handleClickOpenList}
          >
            <span
              style={{
                color: controlsStatus ? '#3E5196' : `${props.state.color}`,
              }}
            >
              {props.state.name}
            </span>
            {
              isOpenList &&
              <>
                <StatusList
                  statusClick={handleChangeStatus}
                  statusList={statusList}
                  handleClickClose={setIsOpenList}
                  forColumn={true}
                  handleDeleteStatus={handleDeleteStatus}
                  handleClickOpenStatus={handleClickOpenStatus}
                />
              </>
            }
          </div>
        }
        {
          isEditName
            ?
            <div className={classNames('flex', styles.renameWrap)}>
              <p className={classNames('hiddenText', styles.backRename)}>
                {changeText}
                текст
              </p>
              <textarea
                className={classNames('resizeArea', styles.renameCol)}
                autoFocus={true}
                onFocus={(e) => e.target.setSelectionRange(changeText.length, changeText.length)}
                defaultValue={changeText}
                onChange={(e) => setChangeText(e.target.value)}
                onBlur={(e) => {
                  handleRename(e)
                  setIsEditName(false)
                }}
              >
              </textarea>
            </div>
            :
            <div className={styles.columnTitle}>
              {props.title}
            </div>
        }
        {
          !props.archived ?
            <ColumnControls
              setIsEditName={setIsEditName}
              setIsOpenAddTask={setIsOpenAddTask}
              setFocusTask={setFocusTask}
              colId={props.colId}
              currentColor={props.color}
              boardId={props.boardId}
              setChangedColor={props.setChangedColor}
            />
            :
            <ArchivedColsControls
              colId={props.colId}
            />
        }
      </div>
      {(isOpenAddTask && !props.archived) &&
        <AddTaskForm
          color={props.color}
          focusTask={focusTask}
          setIsOpenAddTask={setIsOpenAddTask}
          colId={props.colId}
        />
      }
      {
        isOpenStatusPopup &&
        <ColumnStatusPopup
          handleClickClose={handleClickClosePopup}
          colId={props.colId}
          projectId={projectInfo.id}
          create={true}
        />
      }
    </>
  );
}
