import { positionsApi } from "../API/api";

const SET_POSITIONS = 'SET_POSITIONS';
const CREATE_POSITION = 'CREATE_POSITION';

let initialState = {
  positions: [],
  isFetching: false
};

export const usersPositionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_POSITIONS:
      return {
        ...state,
        positions: action.positions,
      }
    case CREATE_POSITION:
      return {
        ...state,
        positions: [...state.positions, action.newPosition],
      }
    default: return { ...state };
  }
}

// +37533 3428547 Артем

const setPositions = (positions) => ({ type: SET_POSITIONS, positions });
const addPosition = (newPosition) => ({ type: CREATE_POSITION, newPosition });

export const getPositions = (organizationId) => async (dispatch) => {
  await positionsApi.getPositions(organizationId)
    .then(response => dispatch(setPositions(response.data)))
    .catch(err => console.log(err))
}
export const createPosition = ({
  organizationId: organizationId,
  title: title
}) => async (dispatch) => {
  return await positionsApi.createPosition({
    organizationId: organizationId,
    title: title
  })
    .then(response => dispatch(addPosition(response.data)))
    .catch(err => console.log(err))
}
