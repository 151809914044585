import classNames from "classnames";
import React, { useEffect, useRef } from "react";
import { createPortal } from "react-dom";
import styles from './commentsfilter.module.css';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import { useDispatch } from "react-redux";
import CloseIcon from "../../../../svgIcons/CloseIcon";
import { useOutsideClick } from "../../../hooks/useOutsideClick";

export default function CommentsFilter(props) {

  const ref = useRef(null)

  useOutsideClick(ref, props.handleClickClose)

  let optionCommentDate = [
    {
      value: `1`,
      label: `Сначала новые`
    },
    {
      value: `0`,
      label: `Сначала старые`
    },
  ];

  const dispatch = useDispatch();

  const {
    clearErrors,
    setError,
    register,
    reset,
    setValue,
    control,
    handleSubmit,
    formState: { errors, isValid }
  } = useForm({
    mode: 'onChange',
  });

  useEffect(() => {
    setValue('date', props.filteredData?.date)
    setValue('for_all', props.filteredData?.for_all)
    setValue('hidden', props.filteredData?.hidden)
    setValue('personal', props.filteredData?.personal)
  }, [setValue])

  const onSubmit = (data => {
    (!data.date &&
      !data.for_all &&
      !data.hidden &&
      !data.personal)
      ?
      dispatch(props.getComments(props.targetId))
        .then(() => {
          props.handleClickClose()
        })
      :
      dispatch(props.getFilteredComments(
        {
          parentId: props.targetId,
          date: data.date,
          for_all: data.for_all,
          hidden: data.hidden,
          personal: data.personal,
        }
      ))
        .then(() => {
          props.handleClickClose()
        })
    console.log(data)
  })

  return createPortal((
    <div className={classNames('flex', 'popup')}>
      <div className={classNames('popupWrapper')}>
        <div className={classNames('popupWindow', 'shadow')} ref={ref}>
          <h3 className={classNames('popupHeader')}>{props.popupHeader}</h3>
          <form
            className={classNames('flex', 'popupform', 'filter')}
            onSubmit={handleSubmit(onSubmit)}
            onChange={() => {
              clearErrors('serverError');
            }}
          >
            <div className={classNames('flex', 'popupInputBox')}>
              <p className={classNames('popupLabel')}>Сортировка по дате</p>
              <div
                onMouseDown={e => {
                  e.stopPropagation()
                }}
                className={'popupInputGroup'}
              >
                <Controller
                  control={control}
                  name='date'
                  render={({ field: { value, onChange } }) => (
                    <Select
                      isSearchable={false}
                      isClearable={true}
                      placeholder='Выбрать'
                      classNamePrefix="react-select"
                      className={classNames('react-select-container')}
                      options={optionCommentDate}
                      value={value ? optionCommentDate.find((с) => с.value === value) : ''}
                      onChange={(val) => onChange(val?.value)}
                    />
                  )}
                />
              </div>
            </div>
            <div className={classNames('flex', 'popupInputBox')}>
              <p className={classNames('popupLabel', 'topLabel')}>Статус</p>
              <div>
                <div className={classNames('flex', 'checkboxContainer')}>
                  <input {...register('for_all')} type="checkbox" id="all" />
                  <label htmlFor="all">Видны всем</label>
                </div>
                <div className={classNames('flex', 'checkboxContainer')}>
                  <input {...register('hidden')} type="checkbox" id="hidden" />
                  <label htmlFor="hidden">Скрытые от заказчика</label>
                </div>
                <div className={classNames('flex', 'checkboxContainer')}>
                  <input {...register('personal')} type="checkbox" id="own" />
                  <label htmlFor="own">Личное</label>
                </div>
              </div>
            </div>
            <div className={classNames('popupBtnsWrapper', styles.btnsWrapper)}>
              <button
                className={classNames('btn', 'btnTransparent')}
                onClick={() => reset()}
                type="button"
              >
                Сбросить
              </button>
              <button
                className={'btn'}
                type='submit'
                disabled={!isValid}
              >
                {props.submitText}
              </button>
            </div>
          </form>
          <button
            className={classNames('closeBtn', 'iconBtn', 'flex')}
            onClick={props.handleClickClose}
          >
            <CloseIcon />
          </button>
        </div>
      </div>
    </div >
  ), document.getElementById('modal_root'))
}
