import classNames from 'classnames';
import React, { lazy, Suspense, useState } from 'react';
import styles from './userpage.module.css';
import { generateKey } from '../../../../utils/generateKey';
import Preloader from '../../../common/Preloader/Preloader';
import { useDispatch, useSelector } from 'react-redux';
import { selectMe } from '../../../../redux/authSelectors';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { selectUserProfile } from '../../../../redux/Profile/userProfileSelector';
import { getUserProfile } from '../../../../redux/Profile/userProfile';
import PageHeader from '../../../common/PageHeader/PageHeader';

const UserContent = lazy(() => import('./UserContent/UserContent'));
const UserHeader = lazy(() => import('./UserHeader/UserHeader'));

export default function UserPage(props) {

  let { userId } = useParams();

  const dispatch = useDispatch();
  const me = useSelector(selectMe);
  const userProfile = useSelector(selectUserProfile);

  const itsMe = +me.user?.id === +userId;
  const imCEO = +me.user?.id === +me?.active_organization?.ceo?.id;
  const user = +me?.user?.id === +userId ? me : userProfile;

  useEffect(() => {
    (me?.user?.id && (+me?.user?.id !== +userId)) && dispatch(getUserProfile(userId, me?.active_organization?.id));
  }, [me.user, userId])

  const [previewColor, setPreviewColor] = useState(null)

  return (
    <div className={classNames('page', 'flex')}>
      <Suspense fallback={<Preloader />}>
        <PageHeader>
          <UserHeader
            name={user?.user?.first_name}
            surname={user?.user?.last_name}
            patronymic={user?.user?.father_name}
          />
        </PageHeader>
        <div
          className={classNames('pageContent', 'pageShadow')}
          style={{ backgroundImage: `url(${me?.user?.background_image})`, backgroundColor: `${previewColor || me.user?.background_color}` }}
        >
          <UserContent
            user={user}
            itsMe={itsMe}
            imCEO={imCEO}
            userId={userId}
            name={user?.user?.first_name}
            surname={user?.user?.last_name}
            patronymic={user?.user?.father_name}
            setPreviewColor={setPreviewColor}
          />
        </div>
      </Suspense>
    </div>
  );
}
