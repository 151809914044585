import React from 'react';

export default function SettingIcon(props) {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2044_251)">
        <path d="M0.75 3.56245H2.802C2.96298 4.15475 3.31438 4.67762 3.80199 5.0504C4.28961 5.42318 4.88634 5.62514 5.50012 5.62514C6.11391 5.62514 6.71064 5.42318 7.19826 5.0504C7.68587 4.67762 8.03727 4.15475 8.19825 3.56245H17.25C17.4489 3.56245 17.6397 3.48343 17.7803 3.34278C17.921 3.20213 18 3.01136 18 2.81245C18 2.61354 17.921 2.42277 17.7803 2.28212C17.6397 2.14147 17.4489 2.06245 17.25 2.06245H8.19825C8.03727 1.47015 7.68587 0.947279 7.19826 0.574502C6.71064 0.201724 6.11391 -0.000244141 5.50012 -0.000244141C4.88634 -0.000244141 4.28961 0.201724 3.80199 0.574502C3.31438 0.947279 2.96298 1.47015 2.802 2.06245H0.75C0.551088 2.06245 0.360322 2.14147 0.21967 2.28212C0.0790176 2.42277 0 2.61354 0 2.81245C0 3.01136 0.0790176 3.20213 0.21967 3.34278C0.360322 3.48343 0.551088 3.56245 0.75 3.56245ZM5.49975 1.49995C5.75934 1.49995 6.0131 1.57693 6.22894 1.72115C6.44478 1.86537 6.613 2.07035 6.71234 2.31018C6.81168 2.55001 6.83767 2.81391 6.78703 3.06851C6.73639 3.32311 6.61138 3.55697 6.42783 3.74053C6.24427 3.92408 6.01041 4.04909 5.75581 4.09973C5.50121 4.15037 5.23731 4.12438 4.99748 4.02504C4.75765 3.9257 4.55267 3.75748 4.40845 3.54164C4.26423 3.3258 4.18725 3.07204 4.18725 2.81245C4.18765 2.46448 4.32606 2.13087 4.57211 1.88481C4.81817 1.63876 5.15178 1.50035 5.49975 1.49995Z" fill="#3E5196" />
        <path d="M17.25 8.24995H15.198C15.0373 7.65751 14.686 7.13445 14.1985 6.76152C13.7109 6.38859 13.1141 6.18652 12.5002 6.18652C11.8864 6.18652 11.2896 6.38859 10.802 6.76152C10.3145 7.13445 9.96321 7.65751 9.8025 8.24995H0.75C0.551088 8.24995 0.360322 8.32897 0.21967 8.46962C0.0790176 8.61027 0 8.80104 0 8.99995C0 9.19886 0.0790176 9.38963 0.21967 9.53028C0.360322 9.67093 0.551088 9.74995 0.75 9.74995H9.8025C9.96321 10.3424 10.3145 10.8654 10.802 11.2384C11.2896 11.6113 11.8864 11.8134 12.5002 11.8134C13.1141 11.8134 13.7109 11.6113 14.1985 11.2384C14.686 10.8654 15.0373 10.3424 15.198 9.74995H17.25C17.4489 9.74995 17.6397 9.67093 17.7803 9.53028C17.921 9.38963 18 9.19886 18 8.99995C18 8.80104 17.921 8.61027 17.7803 8.46962C17.6397 8.32897 17.4489 8.24995 17.25 8.24995ZM12.5002 10.3124C12.2407 10.3124 11.9869 10.2355 11.7711 10.0913C11.5552 9.94703 11.387 9.74205 11.2877 9.50222C11.1883 9.26239 11.1623 8.99849 11.213 8.74389C11.2636 8.48929 11.3886 8.25543 11.5722 8.07187C11.7557 7.88831 11.9896 7.76331 12.2442 7.71267C12.4988 7.66203 12.7627 7.68802 13.0025 7.78736C13.2424 7.8867 13.4473 8.05492 13.5916 8.27076C13.7358 8.4866 13.8127 8.74036 13.8127 8.99995C13.8124 9.34792 13.6739 9.68153 13.4279 9.92759C13.1818 10.1736 12.8482 10.3121 12.5002 10.3124Z" fill="#3E5196" />
        <path d="M17.25 14.4375H8.19825C8.03727 13.8452 7.68587 13.3223 7.19826 12.9495C6.71064 12.5767 6.11391 12.3748 5.50012 12.3748C4.88634 12.3748 4.28961 12.5767 3.80199 12.9495C3.31438 13.3223 2.96298 13.8452 2.802 14.4375H0.75C0.551088 14.4375 0.360322 14.5165 0.21967 14.6571C0.0790176 14.7978 0 14.9885 0 15.1875C0 15.3864 0.0790176 15.5771 0.21967 15.7178C0.360322 15.8584 0.551088 15.9375 0.75 15.9375H2.802C2.96298 16.5297 3.31438 17.0526 3.80199 17.4254C4.28961 17.7982 4.88634 18.0001 5.50012 18.0001C6.11391 18.0001 6.71064 17.7982 7.19826 17.4254C7.68587 17.0526 8.03727 16.5297 8.19825 15.9375H17.25C17.4489 15.9375 17.6397 15.8584 17.7803 15.7178C17.921 15.5771 18 15.3864 18 15.1875C18 14.9885 17.921 14.7978 17.7803 14.6571C17.6397 14.5165 17.4489 14.4375 17.25 14.4375ZM5.49975 16.5C5.24016 16.5 4.9864 16.423 4.77056 16.2788C4.55472 16.1345 4.3865 15.9296 4.28716 15.6897C4.18782 15.4499 4.16183 15.186 4.21247 14.9314C4.26311 14.6768 4.38812 14.4429 4.57167 14.2594C4.75523 14.0758 4.98909 13.9508 5.24369 13.9002C5.49829 13.8495 5.76219 13.8755 6.00202 13.9749C6.24185 14.0742 6.44683 14.2424 6.59105 14.4583C6.73527 14.6741 6.81225 14.9279 6.81225 15.1875C6.81166 15.5354 6.67318 15.8689 6.42717 16.1149C6.18116 16.3609 5.84766 16.4994 5.49975 16.5Z" fill="#3E5196" />
      </g>
      <defs>
        <clipPath id="clip0_2044_251">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
