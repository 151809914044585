
import React, { useState } from 'react';
import styles from './addcommentform.module.css';
import classNames from 'classnames';
import { useForm } from 'react-hook-form';
import CommentAccess from '../CommentAccess/CommentAccess';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import CommentAddFiles from '../CommentAddFiles/CommentAddFiles';
import SendIcon from '../../../../svgIcons/SendIcon';
import { selectMe } from '../../../../redux/authSelectors';
import Emoji from '../Emoji/Emoji';
import 'react-quill/dist/quill.snow.css';
import EditSmallIcon from '../../../../svgIcons/EditSmallIcon';
import { useRef } from 'react';
import PreviewFiles from '../../PreviwFiles/PreviewFiles';
import Editor from '../../Editor/Editor';

export default function AddCommentForm(props) {

  const {
    clearErrors,
    setError,
    getValues,
    setValue,
    register,
    reset,
    handleSubmit,
    formState: { errors, isValid }
  } = useForm({
    mode: 'onChange',
  });

  const [accessValue, setAccessValue] = useState('for_all');
  const [files, setFiles] = useState([]);

  const me = useSelector(selectMe)

  const refWrapper = useRef(null);

  const handleDeleteFile = (arr, item) => {
    const filtered = arr.filter((el) => el.name !== item.name)
    setFiles(filtered);
  }

  const handleKeyDown = (e) => {
    if (e.code.includes('Enter') && e.shiftKey) {
      return
    }
    if (e.code.includes('Enter') && !e.shiftKey) {
      e.preventDefault()
      onSubmit(getValues())
    }
  }

  const showEditor = () => {
    refWrapper.current.classList.toggle('active');
  }

  // For editor (code from lib)
  const [editorHtml, setEditorHtml] = useState('');
  const [quillRef, setQuillRef] = useState(null);
  const reactQuillRef = useRef();

  const attachQuillRefs = () => {
    if (!reactQuillRef.current || typeof reactQuillRef.current.getEditor !== 'function') return;
    if (quillRef) return;

    const editor = reactQuillRef.current.getEditor();
    if (editor !== null) setQuillRef(editor);
  };

  const handleChange = (html) => {
    setEditorHtml(html);
  };

  useEffect(() => {
    attachQuillRefs();
  }, []);

  // ADD Emoji in Quill Editor (body of func is from lib)

  const emojiClick = (emojiObject) => {
    var range = quillRef.getSelection();
    let position = range ? range.index : 0;
    quillRef.insertText(position, emojiObject.native);
  }

  // set values for hidden fields

  useEffect(() => {
    props.access && setValue('display_to_customer', accessValue);
    setValue(props.targetField, props.targetId);
    setValue('author', me.user?.id);
  }, [setValue, accessValue, props.targetId, me])

  // Submit func

  const onSubmit = (data => {
    props.submitFunc(data, files, editorHtml)

    reset()
    setFiles([])
    setEditorHtml('')
  })

  return (
    <div className={styles.formWrap}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className={classNames('flex')}
      >
        <input
          {...register(props.targetField)}
          type="hidden"
        />
        {
          props.access &&
          <input
            {...register('display_to_customer')}
            className={'input'}
            type='hidden'
          />
        }
        <input
          {...register('author')}
          className={'input'}
          type='hidden'
        />
        <div
          className={classNames('quillWrapper', 'addQuillWrapper', 'inputWrapper')}
          ref={refWrapper}
        >
          <Editor
            handleChange={handleChange}
            handleKeyDown={handleKeyDown}
            editorHtml={editorHtml}
            reactQuillRef={reactQuillRef}
          />
          {
            files.length > 0 &&
            <PreviewFiles
              files={files}
              handleDeleteFile={handleDeleteFile}
            />
          }
          <div
            onMouseDown={e => {
              e.preventDefault()
            }}
            className={classNames('flex', styles.access)}
          >
            <button
              type='button'
              onClick={showEditor}
              className={classNames('flex', 'iconBtn')}
            >
              <EditSmallIcon />
            </button>
            {!props.withoutFiles &&
              <CommentAddFiles
                files={files}
                setFiles={setFiles}
                insideParent={props.insideParent}
              />
            }
            <Emoji
              emojiClick={emojiClick}
              insideParent={props.insideParent}
            />
            {
              props.access &&
              <CommentAccess
                setAccessValue={setAccessValue}
                isCheck={accessValue}
                userPage={props.userPage}
              />
            }
          </div>
        </div>
        <button className={classNames('flex', 'iconBtn', styles.sendBtn)}>
          <SendIcon />
        </button>
      </form>
    </div>
  );
}
