import React from 'react';
import styles from './filecard.module.css';
import classNames from 'classnames';
import FileIcon from '../../../../svgIcons/FileIcon';
import DotsIcon from '../../../../svgIcons/DotsIcon';
import { editLongFileName } from '../../../../utils/fileNameEditor';
import { useState } from 'react';
import FileCardMenu from './FileCardMenu/FileCardMenu';
import DeleteIcon from '../../../../svgIcons/DeleteIcon';

export default function FileCard(props) {

  const [isOpenMenu, setIsOpenMenu] = useState(false)

  return (
    <div className={classNames('flex',  styles.card)}>
      <div className={classNames('flex', styles.icon)}>
        <FileIcon />
      </div>
      <div className={classNames('flex', styles.title)}>
        {editLongFileName(props.item.name)}
        <span className={styles.size}>
          {props.item.size}
        </span>
      </div>
      {
        !props.withoutMenu ?
          <button
            onClick={e => {
              e.preventDefault()
              e.stopPropagation()
              setIsOpenMenu(true)
            }}
            className={classNames('flex', 'iconBtn', 'dotsBtn', styles.btn)}
          >
            <DotsIcon />
            {
              isOpenMenu &&
              <FileCardMenu
                item={props.item.id}
                files={props.files}
                fileMenu={props.fileMenu || props.fileMenuDoc}
                setIsOpenMenu={setIsOpenMenu}
              />
            }
          </button>
          :
          props.editFile ?
            <button
              onClick={e => {
                e.preventDefault()
                e.stopPropagation()
                props.handleDeleteFile({ arr: props.files, item: props.item })
              }}
              className={classNames('flex', 'iconBtn', 'dotsBtn', styles.btn)}
            >
              <DeleteIcon />
            </button>
            :
            null
      }
      <a href={props.item.file} className={'absoluteLink'} target="_blank"></a>
    </div>
  );
}
