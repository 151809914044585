import classNames from "classnames";
import React, { useEffect, useRef } from "react";
import { createPortal } from "react-dom";
import styles from './columnfilterpopup.module.css';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import CloseIcon from "../../../../../../../../svgIcons/CloseIcon";
import DatePick from "../../../../../../../common/DatePick/DatePick";
import { useOutsideClick } from "../../../../../../../hooks/useOutsideClick";

export default function ColumnFilterPopup(props) {

  const ref = useRef(null)

  useOutsideClick(ref, props.handleClickClose)

  const dispatch = useDispatch()

  let optionsClient = [
    {
      value: `1`,
      label: 'Это зачем?'
    },
    {
      value: `2`,
      label: 'У проекта'
    },
    {
      value: `3`,
      label: 'Один клиент'
    },
  ];
  let optionsMessage = [
    {
      value: `1`,
      label: 'Непрочитанные'
    },
    {
      value: `2`,
      label: 'Что-то еще'
    },
  ];
  let optionsUsers = []
  props.projectUsers?.map(item =>
    optionsUsers.push({
      value: `${item.user.id}`,
      label: `${item.user.first_name} ${item.user.last_name}`
    })
  );
  let optionsTags = [];
  props.allTagsList?.map(item =>
    optionsTags.push({
      value: `${item.id}`,
      label: `${item.name}`
    }))

  const {
    clearErrors,
    setError,
    getValues,
    control,
    setValue,
    register,
    reset,
    handleSubmit,
    formState: { errors, isValid }
  } = useForm({
    mode: 'onChange',
  });

  const onSubmit = (data => {
    alert(`Фильтруем ${data}`);
    console.log(data);
    props.handleClickClose();
  })

  return (createPortal((
    <div className={classNames('flex', 'popup')}>
      <div className={classNames('popupWrapper')}>
        <div className={classNames('popupWindow', 'shadow')} ref={ref}>
          <h3 className={classNames('popupHeader')}>
            Фильтр
          </h3>
          <form
            className={classNames('flex', 'popupform', 'filter')}
            onSubmit={handleSubmit(onSubmit)}
            onChange={() => {
              clearErrors('serverError');
            }}
          >
            <div className={classNames('flex', 'popupInputBox')}>
              <p className={classNames('popupLabel', 'topLabel')}>Отображать</p>
              <div>
                <div className={classNames('flex', 'checkboxContainer')}>
                  <input {...register('display')} type="checkbox" id="deadline" value="deadline"/>
                  <label htmlFor="deadline">Дедлайн</label>
                </div>
                <div className={classNames('flex', 'checkboxContainer')}>
                  <input {...register('display')} type="checkbox" id="time" value="plantime"/>
                  <label htmlFor="time">Планируемое время</label>
                </div>
                <div className={classNames('flex', 'checkboxContainer')}>
                  <input {...register('display')} type="checkbox" id="commentCount" value="comments"/>
                  <label htmlFor="commentCount">Количество комментариев</label>
                </div>
                <div className={classNames('flex', 'checkboxContainer')}>
                  <input {...register('display')} type="checkbox" id="filesCount" value="files"/>
                  <label htmlFor="filesCount">Количество вложений</label>
                </div>
                <div className={classNames('flex', 'checkboxContainer')}>
                  <input {...register('display')} type="checkbox" id="checklist" values="checklist"/>
                  <label htmlFor="checklist">Чек-лист</label>
                </div>
              </div>
            </div>
            <div className={classNames('flex', 'popupInputBox')}>
              <p className={classNames('popupLabel')}>Дедлайн</p>
              <div className={'popupInputGroup'}>
                <div className={classNames('flex', styles.dateWrap)}>
                  от
                  <Controller
                    control={control}
                    name='dateFrom'
                    render={({ field }) => (
                      <DatePick
                        handlePickDate={field.onChange}
                        startDate={null}
                      />
                    )}
                  />
                  {/* <input
                    className={classNames('input')}
                    type='date'
                    name='dateFrom'
                    {...register('dateFrom')}
                  /> */}
                </div>
                <div className={classNames('flex', styles.dateWrap)}>
                  до
                  <Controller
                    control={control}
                    name='dateTo'
                    render={({ field }) => (
                      <DatePick
                        handlePickDate={field.onChange}
                        startDate={null}
                      />
                    )}
                  />
                  {/* <input
                    className={classNames('input')}
                    type='date'
                    name='dateTo'
                    {...register('dateTo')}
                  /> */}
                </div>
              </div>
            </div>
            <div className={classNames('flex', 'popupInputBox')}>
              <p className={classNames('popupLabel')}>Клиент</p>
              <div
                onMouseDown={e => {
                  e.stopPropagation()
                }}
                className={'popupInputAllView'}
              >
                <Controller
                  control={control}
                  name='client'
                  render={({ field: { value, onChange } }) => (
                    <Select
                      isSearchable={false}
                      isClearable={true}
                      maxMenuHeight={180}
                      menuPlacement={'auto'}
                      placeholder='Выбрать'
                      classNamePrefix="react-select"
                      className={classNames('react-select-container')}
                      options={optionsClient}
                      value={value ? optionsClient.find((с) => с.value === value) : ''}
                      onChange={(val) => onChange(val?.value)}
                    />
                  )}
                />
              </div>
            </div>
            <div className={classNames('flex', 'popupInputBox')}>
              <p className={classNames('popupLabel')}>Исполнитель</p>
              <div
                onMouseDown={e => {
                  e.stopPropagation()
                }}
                className={'popupInputAllView'}
              >
                <Controller
                  control={control}
                  name='performer'
                  render={({ field: { value, onChange } }) => (
                    <Select
                      isSearchable={false}
                      isClearable={true}
                      maxMenuHeight={180}
                      menuPlacement={'auto'}
                      placeholder='Выбрать'
                      classNamePrefix="react-select"
                      className={classNames('react-select-container')}
                      options={optionsUsers}
                      value={value ? optionsUsers.find((с) => с.value === value) : ''}
                      onChange={(val) => onChange(val?.value)}
                    />
                  )}
                />
              </div>
            </div>
            <div className={classNames('flex', 'popupInputBox')}>
              <p className={classNames('popupLabel')}>Метки</p>
              <div
                onMouseDown={e => {
                  e.stopPropagation()
                }}
                className={'popupInputAllView'}
              >
                <Controller
                  control={control}
                  name='tag'
                  render={({ field: { value, onChange } }) => (
                    <Select
                      isSearchable={false}
                      isClearable={true}
                      maxMenuHeight={180}
                      menuPlacement={'auto'}
                      placeholder='Выбрать'
                      classNamePrefix="react-select"
                      className={classNames('react-select-container')}
                      options={optionsTags}
                      value={value ? optionsTags.find((с) => с.value === value) : ''}
                      onChange={(val) => onChange(val?.value)}
                    />
                  )}
                />
              </div>
            </div>
            <div className={classNames('flex', 'popupInputBox')}>
              <p className={classNames('popupLabel')}>Сообщения</p>
              <div
                onMouseDown={e => {
                  e.stopPropagation()
                }}
                className={'popupInputAllView'}
              >
                <Controller
                  control={control}
                  name='messages'
                  render={({ field: { value, onChange } }) => (
                    <Select
                      isSearchable={false}
                      isClearable={true}
                      maxMenuHeight={180}
                      menuPlacement={'auto'}
                      placeholder='Выбрать'
                      classNamePrefix="react-select"
                      className={classNames('react-select-container')}
                      options={optionsMessage}
                      value={value ? optionsMessage.find((с) => с.value === value) : ''}
                      onChange={(val) => onChange(val?.value)}
                    />
                  )}
                />
              </div>
            </div>
            <div className={classNames('popupBtnsWrapper')}>
              <button
                className={classNames('btn', 'btnTransparent')}
                onClick={() => reset()}
                type="button"
              >
                Сбросить
              </button>
              <button
                className={'btn'}
                type='submit'
                disabled={!isValid}
              >
                Применить
              </button>
            </div>
          </form>
          <button
            className={classNames('closeBtn', 'iconBtn', 'flex')}
            onClick={props.handleClickClose}
          >
            <CloseIcon />
          </button>
        </div>
      </div>
    </div >), document.getElementById('modal_root'))
  )
}
