import React, { useState } from 'react';
import styles from './popupcontrols.module.css';
import classNames from 'classnames';
import EditIcon from '../../../svgIcons/EditIcon';
import DeleteIcon from '../../../svgIcons/DeleteIcon';
import NeedleIcon from '../../../svgIcons/NeedleIcon';
import NeedleTrueIcon from '../../../svgIcons/NeedleTrueIcon';
import ConfirmWindow from '../ConfirmWindow/ConfirmWindow';

export default function PopupControls(props) {

  return (
    <div
      onClick={(e) => e.stopPropagation()}
      className={classNames(
        'flex',
        'iconBtnGroup',
        styles.controls,
        props.forComment && styles.controlsComment,
        props.forTableRow && styles.controlsRow,
        props.notAbsolute && styles.relative,
        props.forStaff && styles.staff
      )}>
      <button
        onClick={() => {
          props.handleEdit()
        }}
        className={classNames('flex', 'iconBtn')}
      >
        <EditIcon />
      </button>
      <button
        onClick={() => {
          props.handleClickOpenConfirm()
          props.setIsShowControls(false)
        }}
        className={classNames('flex', 'iconBtn')}
      >
        <DeleteIcon />
      </button>
      {
        (props.forComment && !props.notPin && props.hasPinned) &&
        <button
          onClick={props.handlePin}
          className={classNames('flex', 'iconBtn')}
        >
          {props.pinned ? <NeedleTrueIcon /> : <NeedleIcon />}
        </button>
      }
    </div>
  );
}
