import React from 'react';
import TaskHistory from './TaskHistory';

export default function TaskHistoryContainer(props) {

  const history = [
    {
      time: "2024-10-23T18:47:39.487088+07:00",
      user: "Кот Матроскин",
      event: "Создал задачу",
      path: "Проект/Доска/Колонка"
    },
    {
      time: "2024-11-23T12:00:39.487088+07:00",
      user: "Кот Матроскин",
      event: "Переместил задачу",
      path: "Проект/Доска2/Колонка4"
    },
    {
      time: "2024-11-28T17:12:39.487088+07:00",
      user: "Синий трактор",
      event: "Отметил задачу выполненной",
      path: "Проект/Доска2/Колонка4"
    },
  ]

  return (
    <TaskHistory
      history={history}
    />
  );
}
