import classNames from 'classnames';
import React from 'react';
import CalendarIcon from '../../../../../../../../../svgIcons/CalendarIcon';
import CheckTaskIcon from '../../../../../../../../../svgIcons/CheckTaskIcon';
import CommentIcon from '../../../../../../../../../svgIcons/CommentIcon';
import FileIcon from '../../../../../../../../../svgIcons/FileIcon';
import { editDateToFull } from '../../../../../../../../../utils/dateEditor';
import styles from './taskfooter.module.css';

export default function TaskFooter(props) {

  const allSubTasks = props.subtasks?.length;
  const doneSubTasks = props.subtasks?.filter(item => item.done).length;

  const procent = Math.round(doneSubTasks / allSubTasks * 100)

  return (
    <div
      className={classNames('flex', styles.taskFooter)}
    >
      <div className={classNames('flex', styles.taskMeta)}>
        {
          props.date &&
          <span className={classNames('flex', styles.taskFooterItem)}>
            {editDateToFull(props.date)}
            <CalendarIcon />
          </span>
        }
        {
          props.subtasks?.length > 0 &&
          <span className={classNames('flex', styles.taskFooterItem)}>
            {procent}%
            <button
              className={classNames('flex', 'iconBtn')}
              onClick={(e) => {
                e.stopPropagation()
                props.hadleOpenSub()
              }}
            >
              <CheckTaskIcon />
            </button>
          </span>
        }
      </div>
      <div className={classNames('flex', styles.taskAdditionals)}>
        {
          props.files?.length > 0 &&
          <span className={classNames('flex', styles.taskFooterItem)}>
            {props.files?.length}
            <FileIcon />
          </span>
        }
        {
          props.comments?.length > 0 &&
          <span className={classNames('flex', styles.taskFooterItem)}>
            {props.comments?.length}
            <CommentIcon />
          </span>
        }
      </div>
    </div>
  );
}
