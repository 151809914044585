import React, { useState } from 'react';
import styles from './datepick.module.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ru } from 'date-fns/locale/ru';
import CalendarIcon from '../../../svgIcons/CalendarIcon';
import classNames from "classnames";
import InputMask from 'react-input-mask';
import { useEffect } from 'react';
import { editDateForInput, editDateToFull } from '../../../utils/dateEditor';
import { useCallback } from 'react';

export default function DatePick(props) {

  const [startDate, setStartDate] = useState(props.startDate)
  const [endDate, setEndDate] = useState(new Date())

  // const [mask, setMask] = useState(props.startDate ? editDateToFull(props.startDate) : '')

  useEffect(() => {
    setStartDate(props.range ? new Date((props.startDate).setMonth(props.startDate.getMonth() - 1)) : props.startDate)
  }, [props.startDate])

  const handleRangeChange = ([newStartDate, newEndDate]) => {
    setStartDate(newStartDate);
    setEndDate(newEndDate);
  };

  const handleDefaultChange = (date) => {
    date === '.' ? setStartDate(null) : setStartDate(date);
    props.inTask && props.setDate(date)
  };

  return (
    <DatePicker
      locale={ru}
      showIcon
      // isClearable
      icon={<CalendarIcon />}
      selectsRange={props.range}
      selected={!props.range && startDate}
      startDate={startDate}
      endDate={props.range && endDate}
      onChange={props.range ? handleRangeChange : handleDefaultChange}
      dateFormat="dd.MM.yyyy"
      onCalendarClose={() => props.range ? props.handlePickDate(startDate, endDate) : props.handlePickDate(startDate || '')}
      calendarStartDay={1}
      placeholderText="дд.мм.гггг"
      openToDate={startDate || new Date()}
      autoFocus={props.autofocus}
      className={classNames('pickerDate', props.error && 'pickerError', props.welcome && styles.welcomeInput)}
      customInput={!props.range &&
        <InputMask
          mask="99.99.9999"
          // onInput={e => {
          //   setMask(e.target.value?.replace(/\*|-|_|\s|\$/g, '').replace(/\.\s*$/, ''))
          //   // console.log(e.target.value?.replace(/\*|-|_|\s|\$/g, '').replace(/\.\s*$/, ''))
          // }}
        />
      }
    />
  );
}
