import React, { useState } from 'react';
import styles from './notification.module.css';
import classNames from 'classnames';
import NotificationIcon from '../../../../svgIcons/NotificationIcon';
import Notice from './Notice/Notice';
import { useOutsideClick } from '../../../hooks/useOutsideClick';
import { useRef } from 'react';

export default function Notification(props) {

  const ref = useRef(null)
  const [isShowList, setIsShowList] = useState(false);

  useOutsideClick(ref, setIsShowList)

  return (
    <div className={classNames('flex', styles.notification)}>
      <button
        className={classNames('flex', 'iconBtn')}
        onClick={() => setIsShowList(true)}
      >
        <NotificationIcon />
        <div className={classNames('flex', styles.count)}>
          {props.notices.length}
        </div>
      </button>
      {
        isShowList &&
        <div
          className={classNames('flex', 'shadow', 'contentBlock', styles.list)}
          ref={ref}
        >
          {
            props.notices.map(item =>
              <Notice
                key={item.id}
                item={item}
              />
            )
          }
        </div>
      }
    </div>
  );
}
