import { tasksWithAPI } from "../../API/api";

const SET_USER_TASKS = 'SET_USER_TASKS';

let initialState = {
  userTasks: [],
  isFetching: false
};

export const userTasksReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_TASKS:
      return {
        ...state,
        userTasks: action.userTasks,
      }
    default: return { ...state };
  }
}

const setUserTasks = (userTasks) => ({ type: SET_USER_TASKS, userTasks });

export const getUserTasks = (id) => async (dispatch) => {
  await tasksWithAPI.getTasks(id)
    .then(response => dispatch(setUserTasks(response.data)))
    .catch(err => console.log(err))
}
