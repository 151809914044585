import classNames from 'classnames';
import React, { useState } from 'react';
import { useRef } from 'react';
import { socket } from '../../../../../../../../../../API/socket';
import ArchiveIcon from '../../../../../../../../../../svgIcons/ArchiveIcon';
import CalendarIcon from '../../../../../../../../../../svgIcons/CalendarIcon';
import CheckTaskIcon from '../../../../../../../../../../svgIcons/CheckTaskIcon';
import CopyIcon from '../../../../../../../../../../svgIcons/CopyIcon';
import DeleteIcon from '../../../../../../../../../../svgIcons/DeleteIcon';
import DotsIcon from '../../../../../../../../../../svgIcons/DotsIcon';
import HashIcon from '../../../../../../../../../../svgIcons/HashIcon';
import MoveIcon from '../../../../../../../../../../svgIcons/MoveIcon';
import PlayIcon from '../../../../../../../../../../svgIcons/PlayIcon';
import ShareIcon from '../../../../../../../../../../svgIcons/ShareIcon';
import SubTaskIcon from '../../../../../../../../../../svgIcons/SubTaskIcon';
import UploadIcon from '../../../../../../../../../../svgIcons/UploadIcon';
import { generateKey } from '../../../../../../../../../../utils/generateKey';
import ConfirmWindow from '../../../../../../../../../common/ConfirmWindow/ConfirmWindow';
import SharePopupWrapper from '../../../../../../../../../common/SharePopup/SharePopupWrapper';
import BoardControls from '../../../../../../ProjectHeader/BoardControls/BoardControls';
import TaskCard from '../../TaskCard/TaskCard';
import CheckListPopupWrapper from '../../TaskPopups/CheckListPopup/CheckListPopupWrapper';
import DeadlinePopupWrapper from '../../TaskPopups/DeadlinePopup/DeadlinePopupWrapper';
import SubTaskPopupWrapper from '../../TaskPopups/SubTaskPopup/SubTaskPopupWrapper';
import TagPopupWrapper from '../../TaskPopups/TagPopup/TagPopupWrapper';
import TaskDuplicatePopupWrapper from '../../TaskPopups/TaskDuplicatePopup/TaskDuplicatePopupWrapper';
import TaskFilesPopupWrapper from '../../TaskPopups/TaskFilesPopup/TaskFilesPopupWrapper';
import TaskMovePopupWrapper from '../../TaskPopups/TaskMovePopup/TaskMovePopupWrapper';
import UsersPopupWrapper from '../../TaskPopups/UsersPopup/UsersPopupWrapper';
import styles from './taskcontrols.module.css';
import { createPortal } from "react-dom";
import { useEffect } from 'react';
import LinkIcon from '../../../../../../../../../../svgIcons/LinkIcon';
import { copyLink } from '../../../../../../../../../../utils/copyLink';
import { socketFileApi } from '../../../../../../../../../../API/api';
import { isTouchDevice } from '../../../../../../../../../../utils/isTouchable';
import { selectLabels } from '../../../../../../../../../../redux/BoardPage/сolumnSelector';
import { useDispatch, useSelector } from 'react-redux';
import PauseIcon from '../../../../../../../../../../svgIcons/PauseIcon';
import { createTimerItem, deleteTimerItem, stopTimerItem } from '../../../../../../../../../../redux/Tracker/timerReducer';
import { selectTimers } from '../../../../../../../../../../redux/Tracker/trackerSelector';
import { editTimer } from '../../../../../../../../../../utils/editTimer';

export default function TaskControls(props) {

  const allTagsList = useSelector(selectLabels);
  const ref = useRef(null)
  const refControls = useRef(null)

  const [transformY, setTransformY] = useState(0);
  const [transformX, setTransformX] = useState(0);

  const [isOpenControls, setIsOpenControls] = useState(false);
  const [isOpenConfirmPopup, setIsOpenConfirmPopup] = useState(false);
  const [isOpenTagPopup, setIsOpenTagPopup] = useState(false);
  const [isOpenUserPopup, setIsOpenUserPopup] = useState(false);
  const [isOpenSubTaskPopup, setIsOpenSubTaskPopup] = useState(false);
  const [isOpenDeadlinePopup, setIsOpenDeadlinePopup] = useState(false);
  const [isOpenCheckListPopup, setIsOpenCheckListPopup] = useState(false);
  const [isOpenMovePopup, setIsOpenMovePopup] = useState(false);
  const [isOpenDuplicatePopup, setIsDuplicatePopup] = useState(false);
  const [isOpenFilesPopup, setIsFilesPopup] = useState(false);
  const [isOpenTaskCard, setIsOpenTaskCard] = useState(false);
  const [isOpenTaskShare, setIsOpenTaskShare] = useState(false);
  const [actionKey, setActionKey] = useState('');

  const handleClickOpenConfirm = (key) => {
    setActionKey(key);
    setIsOpenConfirmPopup(true);
    document.body.classList.add('modal-show');
  }
  const handleClickTaskCard = () => {
    setIsOpenTaskCard(true)
    document.body.classList.add('modal-show');
  }
  const handleClickOpenTag = () => {
    setIsOpenTagPopup(true);
    document.body.classList.add('modal-show');
  }
  const handleClickOpenUser = () => {
    setIsOpenUserPopup(true);
    document.body.classList.add('modal-show');
  }
  const handleClickOpenSubTask = () => {
    setIsOpenSubTaskPopup(true);
    document.body.classList.add('modal-show');
  }
  const handleClickOpenDeadline = () => {
    setIsOpenDeadlinePopup(true);
    document.body.classList.add('modal-show');
  }
  const handleClickOpenCheckList = () => {
    setIsOpenCheckListPopup(true);
    document.body.classList.add('modal-show');
  }
  const handleClickOpenMove = (key) => {
    setActionKey(key);
    setIsOpenMovePopup(true);
    document.body.classList.add('modal-show');
  }
  const handleClickOpenDuplicate = () => {
    setIsDuplicatePopup(true);
    document.body.classList.add('modal-show');
  }
  const handleClickOpenFiles = () => {
    setIsFilesPopup(true);
    document.body.classList.add('modal-show');
  }
  const handleClickOpenShare = () => {
    setIsOpenTaskShare(true)
    document.body.classList.add('modal-show');
  }

  const handleClickClosePopup = () => {
    isOpenConfirmPopup && setIsOpenConfirmPopup(false);
    isOpenTaskCard && setIsOpenTaskCard(false);
    isOpenTagPopup && setIsOpenTagPopup(false);
    isOpenUserPopup && setIsOpenUserPopup(false);
    isOpenSubTaskPopup && setIsOpenSubTaskPopup(false);
    isOpenDeadlinePopup && setIsOpenDeadlinePopup(false);
    isOpenCheckListPopup && setIsOpenCheckListPopup(false);
    isOpenMovePopup && setIsOpenMovePopup(false);
    isOpenDuplicatePopup && setIsDuplicatePopup(false);
    isOpenFilesPopup && setIsFilesPopup(false);
    isOpenTaskShare && setIsOpenTaskShare(false);
    setActionKey('');
    document.body.classList.remove('modal-show');
  }

  const handleDeleteTask = () => {
    socket.emit('deleteTask', props.task.id);
    handleClickClosePopup();
  }

  const handleArchiveTask = () => {
    socket.emit('ChangeTaskArchived', {
      'taskId': props.task.id,
      'archived': true
    })

    handleClickClosePopup()
  }
  const uploadFiles = (files) => {

    socketFileApi.setTaskFiles(files)
      .then(res => {
        socket.emit('uploadFiles', {
          'taskId': props.task.id,
          'fileIds': res.data
        })
      })

    handleClickClosePopup()
  }

  const addTagToTask = (id) => {
    socket.emit('AddLabel', {
      'taskId': props.task.id,
      'labelId': id,
    })
  }

  const controlsList = [
    // {
    //   icon: <SettingIcon />,
    //   name: 'Настройка',
    //   callBack: handleClickTaskCard,
    //   id: generateKey(),
    // },
    {
      icon: <SubTaskIcon />,
      name: 'Подзадача',
      callBack: handleClickOpenSubTask,
      id: generateKey(),
    },
    // {
    //   icon: <UserIcon />,
    //   name: 'Участники',
    //   callBack: handleClickOpenUser,
    //   id: generateKey(),
    // },
    {
      icon: <HashIcon />,
      name: 'Метка',
      callBack: handleClickOpenTag,
      withList: !isTouchDevice() && allTagsList?.length > 0,
      list: allTagsList,
      listItemCallback: addTagToTask,
      id: generateKey(),
    },
    {
      icon: <CalendarIcon />,
      name: 'Дата',
      callBack: handleClickOpenDeadline,
      id: generateKey(),
    },
    {
      icon: <UploadIcon />,
      name: 'Вложения',
      callBack: handleClickOpenFiles,
      id: generateKey(),
    },
    {
      icon: <CheckTaskIcon />,
      name: 'Чек-лист',
      callBack: handleClickOpenCheckList,
      id: generateKey(),
      hidden: !props.task.checklists?.items ? false : true,
    },
    {
      icon: <CopyIcon />,
      name: 'Копировать в...',
      callBack: handleClickOpenMove,
      id: generateKey(),
      key: 'copy'
    },
    {
      icon: <MoveIcon />,
      name: 'Переместить в...',
      callBack: handleClickOpenMove,
      id: generateKey(),
      key: 'move'
    },
    {
      icon: <CopyIcon />,
      name: 'Дублировать',
      callBack: handleClickOpenDuplicate,
      id: generateKey(),
    },
    // {
    //   icon: <ShareIcon />,
    //   name: 'Поделиться',
    //   callBack: handleClickOpenShare,
    //   id: generateKey(),
    // },
    {
      icon: <ArchiveIcon />,
      name: 'Архивировать',
      callBack: handleClickOpenConfirm,
      id: generateKey(),
      key: 'archive'
    },
    {
      icon: <DeleteIcon />,
      name: 'Удалить',
      callBack: handleClickOpenConfirm,
      id: generateKey(),
      key: 'delete'
    },
    {
      icon: <LinkIcon />,
      name: 'Копировать ссылку',
      callBack: () => copyLink(`${(window.location.href).split('#', [1])}#task-${props.task.id}`, `задачу #task-${props.task.id}`),
      id: generateKey(),
    },
  ]

  const winHeight = window.innerHeight;
  const winWidth = window.innerWidth;

  useEffect(() => {
    const contY = refControls.current?.getBoundingClientRect().y;
    const contHeight = refControls.current?.getBoundingClientRect().height;

    const contX = refControls.current?.getBoundingClientRect().x;
    const contWidth = refControls.current?.getBoundingClientRect().width;

    (contY + contHeight) > winHeight ? setTransformY(contY + contHeight - winHeight + 10) : setTransformY(0);
    (contX + contWidth) > winWidth ? setTransformX(contX + contWidth - winWidth + 10) : setTransformX(0);

    return () => { }

  }, [isOpenControls])

  // TIMER BLOCK

  const dispatch = useDispatch();
  const timers = useSelector(selectTimers);

  const timer = timers.find(tim => +tim?.task === +props.task.id)

  const [timerStart, setTimerStart] = useState(timer?.intervals[timer?.intervals?.length - 1]?.end_time === null);
  const [time, setTime] = useState(timer?.total_duration);
  const [showDel, setShowDel] = useState(false);

  useEffect(() => {
    setTimerStart(timer?.intervals[timer?.intervals?.length - 1]?.end_time === null);

    return () => { }
  }, [timer?.intervals])

  useEffect(() => {
    setTime(timer?.total_duration)

    return () => { }
  }, [timer?.total_duration])

  const createTimer = () => {
    dispatch(createTimerItem(props.task.id))
      .then(() => setTimerStart(true))
  }

  const stopTimer = () => {
    dispatch(stopTimerItem(props.task.id))
      .then(() => setTimerStart(false))
  }

  const deleteTimer = () => {
    dispatch(deleteTimerItem(timer?.id))
      .then(() => setTimerStart(false))
  }

  useEffect(() => {
    let timerItem;

    if (timerStart) {
      timerItem = setTimeout(() => setTime(prev => prev + 1), 1000)
    } else {
      clearInterval(timerItem)
    }

    return () => { clearInterval(timerItem) }
  }, [time, timerStart]);

  return (
    <div
      className={classNames('flex', 'iconBtnGroup', styles.taskControls)}
      ref={ref}
    >
      <button
        onClick={() => setIsOpenControls(true)}
        className={classNames('flex', 'iconBtn', 'dotsBtn')}
      >
        <DotsIcon />
      </button>
      {
        timer &&
        <div
          onMouseEnter={() => setShowDel(true)}
          onMouseLeave={() => setShowDel(false)}
          className={classNames(styles.timer, timerStart && styles.active)}>
          {
            editTimer(time)
          }
          {
            showDel &&
            <button
              onClick={() => deleteTimer()}
              className={classNames(styles.delBtn, 'iconBtn', 'flex')}
            >
              <DeleteIcon />
            </button>
          }

        </div>
      }
      {
        !timerStart ?
          <button
            className={classNames('flex', 'iconBtn')}
            onClick={() => {
              createTimer()
            }}
          >
            <PlayIcon />
          </button>
          :
          <button
            className={classNames('flex', 'iconBtn')}
            onClick={() => {
              stopTimer()
            }}
          >
            <PauseIcon />
          </button>
      }
      {
        isOpenControls &&
        createPortal((
          <div
            ref={refControls}
            className={'absolutePortal'}
            style={{
              top: `${(ref.current?.getBoundingClientRect().y || 0) + ref.current?.getBoundingClientRect().height + window.scrollY + 5}px`,
              left: `${(ref.current?.getBoundingClientRect().x || 0) + 20}px`,
              transform: `translateY(${-transformY}px) translateX(${-transformX}px)`
            }}
          >
            <BoardControls
              handleClickClose={setIsOpenControls}
              controlsList={controlsList}
              taskPos={true}
            />
          </div>
        ), document.getElementById('modal_root'))
      }
      {
        isOpenConfirmPopup &&
        <ConfirmWindow
          title={actionKey === 'delete' ? 'Удалить задачу?' : 'Архивировать задачу?'}
          onDelete={actionKey === 'delete' ? handleDeleteTask : handleArchiveTask}
          closeDelete={handleClickClosePopup}
          btnText={actionKey !== 'delete' ? 'Подтвердить' : false}
        />
      }
      {
        isOpenTaskCard &&
        <TaskCard
          project={props.project}
          task={props.task}
          column={props.column}
          handleClickClose={handleClickClosePopup}
          allTagsList={props.allTagsList}
        />
      }
      {
        isOpenTagPopup &&
        <TagPopupWrapper
          taskId={props.task.id}
          handleClickClose={handleClickClosePopup}
          boardId={props.boardId}
        />
      }
      {
        isOpenUserPopup &&
        <UsersPopupWrapper
          project={props.project}
          taskId={props.task.id}
          users={props.task.performers}
          handleClickClose={handleClickClosePopup}
        />
      }
      {
        isOpenSubTaskPopup &&
        <SubTaskPopupWrapper
          taskId={props.task.id}
          handleClickClose={handleClickClosePopup}
        />
      }
      {
        isOpenDeadlinePopup &&
        <DeadlinePopupWrapper
          deadline={props.task.deadline}
          taskId={props.task.id}
          handleClickClose={handleClickClosePopup}
        />
      }
      {
        isOpenCheckListPopup &&
        <CheckListPopupWrapper
          handleClickClose={handleClickClosePopup}
          taskId={props.task.id}
          boardId={props.boardId}
        />
      }
      {
        isOpenMovePopup &&
        <TaskMovePopupWrapper
          keyAct={actionKey === 'move' ? 'move' : 'copy'}
          handleClickClose={handleClickClosePopup}
          taskComm={props.task.comments}
          taskAddit={props.task.attachments}
          taskCheck={props.task.checklists}
          taskName={props.task.name}
          taskId={props.task.id}
          subtasks={props.task.subtasks}
        />
      }
      {
        isOpenDuplicatePopup &&
        <TaskDuplicatePopupWrapper
          colId={props.colId}
          taskId={props.task.id}
          taskName={props.task.name}
          taskComm={props.task.comments}
          taskAddit={props.task.attachments}
          taskCheck={props.task.checklists}
          handleClickClose={handleClickClosePopup}
          subtasks={props.task.subtasks}
        />
      }
      {
        isOpenFilesPopup &&
        <TaskFilesPopupWrapper
          forTasks={true}
          handleClickClose={handleClickClosePopup}
          withLinks={true}
          uploadFiles={uploadFiles}
        />
      }
      {
        isOpenTaskShare && <SharePopupWrapper
          handleClickClose={handleClickClosePopup}
          popupHeader={`Поделиться задачей`}
        />
      }
    </div>
  );
}
