import classNames from "classnames";
import React, { useEffect, useRef } from "react";
import { createPortal } from "react-dom";
import styles from './sharepopup.module.css';
import { useForm } from 'react-hook-form';
import UserInfo from "../UserInfo/UserInfo";
import UnlockRoundIcon from "../../../svgIcons/UnlockRoundIcon";
import LockRoundIcon from "../../../svgIcons/LockRoundIcon";
import LinkIcon from "../../../svgIcons/LinkIcon";
import CloseIcon from "../../../svgIcons/CloseIcon";
import { useOutsideClick } from "../../hooks/useOutsideClick";

export default function SharePopup(props) {

  const ref = useRef(null)

  useOutsideClick(ref, props.handleClickClose)

  const {
    clearErrors,
    setError,
    getValues,
    register,
    reset,
    handleSubmit,
    formState: { errors, isValid }
  } = useForm({
    mode: 'onChange',
  });

  const onSubmit = (data => {
    console.log(data);
  })

  return (
    <div className={classNames('flex', 'popup')}>
      <div className={classNames('popupWrapper')}>
        <div className={classNames('popupWindow', 'shadow')} ref={ref}>
          <h3 className={classNames('popupHeader')}>
            {props.popupHeader}
          </h3>
          <form
            className={classNames('flex', 'popupform', 'filter')}
            onSubmit={handleSubmit(onSubmit)}
            onChange={() => {
              clearErrors('serverError');
            }}
          >
            <div className={classNames('popupInputBox')}>
              <input
                className={classNames('input')}
                type='text'
                name='user'
                placeholder='Добавить пользователя'
                {...register('user')}
              />
            </div>
            <div>
              <div className={styles.blockTitle}>Пользователи имеющие доступ</div>
              <div className={classNames('flex', styles.usersList)}>
                <UserInfo
                  avatar={props.avatar}
                  first_name={'Имя'}
                  last_name={'Фамилия'}
                  email={'fff@ff.rr'}
                  isFull={true}
                />
                <UserInfo
                  avatar={props.avatar}
                  first_name={'Имя'}
                  last_name={'Фамилия'}
                  email={'fff@ff.rr'}
                  isFull={true}
                />
              </div>
            </div>
            <div className={styles.accessContainer}>
              <div className={styles.blockTitle}>Общий доступ</div>
              <div className={classNames('flex')}>
                <div className={classNames('flex', styles.accessIcon)}>
                  {
                    getValues('access') === '0' ?
                      <UnlockRoundIcon />
                      :
                      <LockRoundIcon />
                  }
                </div>
                <div className={classNames('flex', styles.accessWrapper)}>
                  <select {...register('access')}
                    className={styles.accessSelect}
                  >
                    <option value='0'>Все у кого есть доступ</option>
                    <option value='1'>Доступ ограничен</option>
                  </select>
                  <p className={styles.accessDescr}>
                    {
                      getValues('access') === '0' ?
                        'Просматривать могут все, у кого есть ссылка'
                        :
                        'Открывать контент по этой ссылке могут только пользователи, имеющие доступ'
                    }
                  </p>
                </div>
              </div>
            </div>
            <div className={classNames('flex', 'popupBtnsWrapper', styles.fileBtnGroup)}>
              <button
                type='button'
                className={classNames('flex', 'iconBtn')}
              >
                <div className={classNames('flex', styles.linkIcon)}>
                  <LinkIcon />
                </div>
                Копировать ссылку
              </button>
              <button
                className={'btn'}
                type='submit'
                disabled={!isValid}
              >
                Готово
              </button>
            </div>
          </form>
          <button
            className={classNames('closeBtn', 'iconBtn', 'flex')}
            onClick={props.handleClickClose}
          >
            <CloseIcon />
          </button>
        </div>
      </div>
    </div>
  )
}
