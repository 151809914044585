import classNames from "classnames";
import React, { useEffect, useRef } from "react";
import styles from './userspopup.module.css';
import { Controller, set, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import CloseIcon from "../../../../../../../../../../svgIcons/CloseIcon";
import { generateKey } from "../../../../../../../../../../utils/generateKey";
import { redirect } from "react-router-dom";
import AddIcon from "../../../../../../../../../../svgIcons/AddIcon";
import { useState } from "react";
import UserInfo from "../../../../../../../../../common/UserInfo/UserInfo";
import { socket } from "../../../../../../../../../../API/socket";
import TaskUserWrapper from "../../TaskCard/TaskCardUsers/TaskUserWrapper";
import { useOutsideClick } from "../../../../../../../../../hooks/useOutsideClick";

export default function UsersPopup(props) {

  const ref = useRef(null)

  useOutsideClick(ref, props.handleClickClose)

  let optionsUsers = []
  props.project?.workers?.map(item =>
    optionsUsers.push({
      value: `${item.id}`,
      label: `${item.user.first_name} ${item.user.last_name}`
    })
  );

  const {
    clearErrors,
    setError,
    getValues,
    control,
    setValue,
    register,
    reset,
    handleSubmit,
    formState: { errors, isValid }
  } = useForm({
    mode: 'onChange',
  });

  const onSubmit = (data => {
    socket.emit('AddTaskPerformer',
      {
        'taskId': props.taskId,
        'projectuserId': +data.user
      })
    setValue('user', '')
  })

  return (
    <div className={classNames('flex', 'popup')}>
      <div className={classNames('popupWrapper')}>
        <div className={classNames('popupWindow', 'shadow', styles.usersWrap)} ref={ref}>
          <h3 className={classNames('popupHeader')}>
            Участники
          </h3>
          <form
            className={classNames('flex', 'popupform', 'filter')}
            onSubmit={handleSubmit(onSubmit)}
            onChange={() => {
              clearErrors('serverError');
            }}
          >
            {
              props.users.length > 0 &&
              <div className={classNames('flex', styles.users)}>
                {
                  props.users.map(item =>
                    <TaskUserWrapper
                      key={item.user?.id}
                      avatar={item.user?.avatar}
                      first_name={item.user?.first_name}
                      last_name={item.user?.last_name}
                      email={item.position?.name}
                      userId={item.id}
                      taskId={props.taskId}
                    />
                  )
                }
              </div>
            }
            <div className={classNames('flex', 'popupInputBox')}>
              <p className={classNames('popupLabel')}>Добавить участника</p>
              <div
                onMouseDown={e => {
                  e.stopPropagation()
                }}
                className={'popupInputAllView'}
              >
                <Controller
                  control={control}
                  name='user'
                  render={({ field: { value, onChange } }) => (
                    <Select
                      isSearchable={false}
                      isClearable={true}
                      maxMenuHeight={180}
                      menuPlacement={'auto'}
                      placeholder='Выбрать'
                      classNamePrefix="react-select"
                      className={classNames('react-select-container')}
                      options={optionsUsers}
                      value={value ? optionsUsers.find((с) => с.value === value) : ''}
                      onChange={(val) => onChange(val?.value)}
                    />
                  )}
                  rules={{ required: 'Выберите проект' }}
                />
              </div>
            </div>
            <div className={classNames('popupBtnsWrapper')}>
              <button
                className={'btn'}
                type='submit'
                disabled={!isValid}
              >
                Добавить
              </button>
            </div>
          </form>
          <button
            className={classNames('closeBtn', 'iconBtn', 'flex')}
            onClick={props.handleClickClose}
          >
            <CloseIcon />
          </button>
        </div>
      </div>
    </div >
  )
}
