import React, { useEffect, useRef, useState } from 'react';
import styles from './emoji.module.css';
import classNames from 'classnames';
import EmojiIcon from '../../../../svgIcons/EmojiIcon';
import data from '@emoji-mart/data'
import Picker from '@emoji-mart/react'
import { useOutsideClick } from '../../../hooks/useOutsideClick';
import { createPortal } from 'react-dom';

export default function Emoji(props) {
  const ref = useRef(null)
  const refButton = useRef(null)
  const [openEmoji, setOpenEmoji] = useState(false)

  useOutsideClick(ref, setOpenEmoji)

  return (
    <div className={classNames('flex', styles.emojiWrap)} ref={refButton}>
      <button
        onClick={() => setOpenEmoji(true)}
        type="button"
        className={classNames('flex', 'iconBtn')}
      >
        <EmojiIcon />
      </button>
      {
        openEmoji ?
          !props.insideParent ?
            createPortal((
              <div
                ref={ref}
                className={classNames('absolutePortal', styles.emojiBox)}
                style={{
                  top: `${(refButton.current?.getBoundingClientRect().y || 0) + window.scrollY - 20}px`,
                  left: `${(refButton.current?.getBoundingClientRect().x || 0) + 100}px`,
                  transform: `translateY(-100%) translateX(-100%)`
                }}
              >
                <Picker
                  data={data}
                  onEmojiSelect={e => props.emojiClick(e)}
                  locale='ru'
                  previewPosition='bottom'
                  dynamicWidth={true}
                  skin={1}
                  autoFocus={false}
                  searchPosition={'none'}
                  skinTonePosition={'preview'}
                />
              </div>
            ), document.getElementById('modal_root'))
            :
            <div
              ref={ref}
              className={classNames(styles.emojiBox, styles.notPortal)}
            >
              <Picker
                data={data}
                onEmojiSelect={e => props.emojiClick(e)}
                locale='ru'
                previewPosition='bottom'
                dynamicWidth={true}
                skin={1}
                autoFocus={false}
                searchPosition={'none'}
                skinTonePosition={'preview'}
              />
            </div> : null
      }
    </div>
  );
}
