import React from 'react';
import styles from './userpopup.module.css';
import classNames from 'classnames';
import { useRef } from 'react';
import { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import UserIcon from '../../../../../svgIcons/UserIcon';
import ExitIcon from '../../../../../svgIcons/ExitIcon';

export default function UserPopup(props) {

  const exitHandle = () => {
    props.handleClick()
  }

  return (
    <div className={classNames(styles.popupWrapper)}>
      <div className={classNames('shadow', 'menuList', styles.userPopup)}>
        <div className={classNames(styles.popupBtnBox, styles.cabinetBtnBox)}>
          <NavLink
            className={classNames('flex', styles.popupBtn)}
            to={`/user/${props.me?.user?.id}`}
          >
            <UserIcon />
            <span>Личный кабинет</span>
          </NavLink>
        </div>
        <div className={classNames(styles.popupBtnBox, styles.exitBtnBox)}>
          <button
            className={classNames('flex', styles.popupBtn)}
            onClick={exitHandle}
          >
            <ExitIcon />
            <span>Выйти</span>
          </button>
        </div>
      </div>
    </div>

  );
}
