import classNames from 'classnames';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { editName, editNameWithoutDots } from '../../../utils/nameEditor';
import styles from './userroundlink.module.css';

export default function UserRoundLink(props) {

  return (
    <NavLink
      to={`/dash/${props.item?.user?.id || props.item?.id}`}
      style={{
        backgroundColor: `${props.item?.user?.avatar_color || props.item?.avatar_color}`,
        'cursor': props.archived && 'auto',
        width: `${props.width}px`,
        height: `${props.height}px`
      }}
      className={classNames(props.toLeft && styles.toLeft, styles.user, props.toTop && styles.toTop)}
      onClick={(e) => {
        e.stopPropagation()
        props.archived && e.preventDefault()
      }}
    >
      <div className={classNames('imageBox', 'flex', styles.userImage)}>
        {
          (props.item?.user?.avatar || props.item?.avatar) ?
            <img src={props.item?.user?.avatar || props.item?.avatar} alt="ava" />
            :
            <span className={styles.initial}>
              {editNameWithoutDots(props.item.user?.last_name || props.item?.last_name)}{editNameWithoutDots(props.item.user?.first_name || props.item?.first_name)}
            </span>
        }
      </div>
      <span className={styles.tooltip}>
        {props.item.user?.last_name || props.item?.last_name} {editName(props.item.user?.first_name || props.item?.first_name)}
      </span>
    </NavLink>
  );
}
