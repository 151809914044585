import React from 'react';
import CommentItemEdit from './CommentItemContent/CommentItemEdit';
import CommentItemContent from './CommentItemContent/CommentItemContent';
import { useState } from 'react';

export default function CommentItem(props) {

  const [isEdit, setIsEdit] = useState(false);
  const [isShowFiles, setIsShowFiles] = useState(false);
  const handleShowFiles = () => {
    isShowFiles ? setIsShowFiles(false) : setIsShowFiles(true);
  }

  return (
    isEdit
      ?
      <CommentItemEdit
        isShowFiles={isShowFiles}
        handleShowFiles={handleShowFiles}
        setIsEdit={setIsEdit}
        item={props.item}
        targetId={props.targetId}
        handleEdit={props.handleEdit}
        access={props.access}
        insideParent={props.insideParent}
        getComments={props.getComments}
        getFilteredComments={props.getFilteredComments}
        filteredData={props.filteredData}
        filtered={props.filtered}
        inTask={props.inTask}
        withoutFiles={props.withoutFiles}
        userPage={props.userPage}
      />
      :
      <CommentItemContent
        isShowFiles={isShowFiles}
        handleShowFiles={handleShowFiles}
        setIsEdit={setIsEdit}
        item={props.item}
        targetId={props.targetId}
        hasPinned={props.hasPinned}
        handleDelete={props.handleDelete}
        handleEditPinned={props.handleEditPinned}
        getComments={props.getComments}
        getFilteredComments={props.getFilteredComments}
        filteredData={props.filteredData}
        filtered={props.filtered}
        insideParent={props.insideParent}
        forComment={props.forComment}
        readOnly = {props.readOnly}
      />
  );
}
