import { usersAPI } from "../API/api";
import { mapObj } from "../utils/objectHelpers";

const SET_CLIENTS = 'SET_CLIENTS';
const SET_USERS = 'SET_USERS';
const EDIT_USER = 'EDIT_USER';
const SET_INVITES = 'SET_INVITES';

let initialState = {
  clients: [],
  users: [],
  invites: [],
  isFetching: false
};

export const usersReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CLIENTS:
      return {
        ...state,
        clients: action.clients,
      }
    case SET_USERS:
      return {
        ...state,
        users: action.users,
      }
    case EDIT_USER:
      return {
        ...state,
        users: mapObj(state.users, action.userId, 'id', action.newObj)
      }
    case SET_INVITES:
      return {
        ...state,
        invites: action.invites,
      }
    default: return { ...state };
  }
}

const setClients = (clients) => ({ type: SET_CLIENTS, clients });
const setUsers = (users) => ({ type: SET_USERS, users });
const editUser = (userId, newObj) => ({ type: EDIT_USER, userId, newObj });
const setInvites = (invites) => ({ type: SET_INVITES, invites });

export const getClients = (organizationId) => async (dispatch) => {
  await usersAPI.getClients(organizationId)
    .then(response => dispatch(setClients(response.data)))
    .catch(err => console.log(err))
}
export const getUsers = (organizationId) => async (dispatch) => {
  await usersAPI.getUsers(organizationId)
    .then(response => dispatch(setUsers(response.data)))
    .catch(err => console.log(err))
}

export const editUserCompany = ({
  userId: userId,
  orgId: orgId,
  hourly_rate: hourly_rate,
  monthly_rate: monthly_rate,
  position_id: position_id,
  role_id: role_id,
}) => async (dispatch) => {
  await usersAPI.editUser({
    userId: userId,
    orgId: orgId,
    hourly_rate: hourly_rate,
    monthly_rate: monthly_rate,
    position_id: position_id,
    role_id: role_id,
  })
    .then(response => dispatch(editUser(userId, response.data)))
    .catch(err => console.log(err))
}

export const getInvites = (orgId) => async (dispatch) => {
  await usersAPI.getInvites(orgId)
    .then(response => dispatch(setInvites(response.data)))
    .catch(err => console.log(err))
}
