import { statusesAPI } from "../API/api";
import { mapObj } from "../utils/objectHelpers";

const SET_PROJECT_STATUSES = 'SET_PROJECT_STATUSES';
const CREATE_PROJECT_STATUSES = 'CREATE_PROJECT_STATUSES';
const DELETE_PROJECT_STATUS = 'DELETE_PROJECT_STATUS';
const EDIT_PROJECT_STATUS = 'EDIT_PROJECT_STATUS';

const SET_PROJECT_DATE_STATUSES = 'SET_PROJECT_DATE_STATUSES';
const CREATE_PROJECT_DATE_STATUS = 'CREATE_PROJECT_DATE_STATUS';
const DELETE_PROJECT_DATE_STATUS = 'DELETE_PROJECT_DATE_STATUS';
const EDIT_PROJECT_DATE_STATUS = 'EDIT_PROJECT_DATE_STATUS';

const SET_PROJECT_DATE_ATTRIBUTES = 'SET_PROJECT_DATE_ATTRIBUTES';
const CREATE_PROJECT_DATE_ATTRIBUTE = 'CREATE_PROJECT_DATE_ATTRIBUTE';
const DELETE_PROJECT_DATE_ATTRIBUTE = 'DELETE_PROJECT_DATE_ATTRIBUTE';
const EDIT_PROJECT_DATE_ATTRIBUTE = 'EDIT_PROJECT_DATE_ATTRIBUTE';

let initialState = {
  projectStatuses: [],
  dateStatuses: [],
  dateAttributes: [],
  isFetching: false
};

export const projectStatusesReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PROJECT_STATUSES:
      return {
        ...state,
        projectStatuses: action.projectStatuses,
      }
    case CREATE_PROJECT_STATUSES:
      return {
        ...state,
        projectStatuses: [...state.projectStatuses, action.newStatus],
      }
    case DELETE_PROJECT_STATUS:
      return {
        ...state,
        projectStatuses: state.projectStatuses.filter((el) => el.id !== action.id),
      }
    case EDIT_PROJECT_STATUS:
      return {
        ...state,
        projectStatuses: mapObj(state.projectStatuses, action.statusId, 'id', action.newObj),
      }

    case SET_PROJECT_DATE_STATUSES:
      return {
        ...state,
        dateStatuses: action.dateStatuses,
      }
    case CREATE_PROJECT_DATE_STATUS:
      return {
        ...state,
        dateStatuses: [...state.dateStatuses, action.newItem],
      }
    case DELETE_PROJECT_DATE_STATUS:
      return {
        ...state,
        dateStatuses: state.dateStatuses.filter((el) => el.id !== action.id),
      }
    case EDIT_PROJECT_DATE_STATUS:
      return {
        ...state,
        dateStatuses: mapObj(state.dateStatuses, action.itemId, 'id', action.newObj),
      }

    case SET_PROJECT_DATE_ATTRIBUTES:
      return {
        ...state,
        dateAttributes: action.dateAttributes,
      }
    case CREATE_PROJECT_DATE_ATTRIBUTE:
      return {
        ...state,
        dateAttributes: [...state.dateAttributes, action.newItem],
      }
    case DELETE_PROJECT_DATE_ATTRIBUTE:
      return {
        ...state,
        dateAttributes: state.dateAttributes.filter((el) => el.id !== action.id),
      }
    case EDIT_PROJECT_DATE_ATTRIBUTE:
      return {
        ...state,
        dateAttributes: mapObj(state.dateAttributes, action.itemId, 'id', action.newObj),
      }
    default: return { ...state };
  }
}

const setProjectStatuses = (projectStatuses) => ({ type: SET_PROJECT_STATUSES, projectStatuses });
const setCreateProjectStatus = (newStatus) => ({ type: CREATE_PROJECT_STATUSES, newStatus });
const setEditProjectStatus = (statusId, newObj) => ({ type: EDIT_PROJECT_STATUS, statusId, newObj });
const setDeleteProjectStatus = (id) => ({ type: DELETE_PROJECT_STATUS, id });

const setDateStatuses = (dateStatuses) => ({ type: SET_PROJECT_DATE_STATUSES, dateStatuses });
const setCreateProjectDateStatus = (newItem) => ({ type: CREATE_PROJECT_DATE_STATUS, newItem });
const setEditProjectDateStatus = (itemId, newObj) => ({ type: EDIT_PROJECT_DATE_STATUS, itemId, newObj });
const setDeleteProjectDateStatus = (id) => ({ type: DELETE_PROJECT_DATE_STATUS, id });

const setDateAttributes = (dateAttributes) => ({ type: SET_PROJECT_DATE_ATTRIBUTES, dateAttributes });
const setCreateProjectDateAttribute = (newItem) => ({ type: CREATE_PROJECT_DATE_ATTRIBUTE, newItem });
const setEditProjectDateAttribute = (itemId, newObj) => ({ type: EDIT_PROJECT_DATE_ATTRIBUTE, itemId, newObj });
const setDeleteProjectDateAttribute = (id) => ({ type: DELETE_PROJECT_DATE_ATTRIBUTE, id });

export const getProjectStatuses = (projectId) => async (dispatch) => {
  await statusesAPI.getProjectStatuses(projectId)
    .then(response => dispatch(setProjectStatuses(response.data)))
    .catch(err => console.log(err))
}
export const createProjectStatus = ({
  id: id,
  name: name,
  color: color
}) => async (dispatch) => {
  await statusesAPI.createProjectStatuses({
    id: id,
    name: name,
    color: color
  })
    .then(response => dispatch(setCreateProjectStatus(response.data)))
    .catch(err => console.log(err))
}
export const deleteProjectStatus = (id) => async (dispatch) => {
  await statusesAPI.deleteProjectStatus(id)
    .then(response => dispatch(setDeleteProjectStatus(id)))
    .catch(err => console.log(err))
}
export const editProjectStatus = ({
  id: id,
  name: name,
  color: color
}) => async (dispatch) => {
  await statusesAPI.editProjectStatus({
    id: id,
    name: name,
    color: color
  })
    .then(response => dispatch(setEditProjectStatus(id, response.data)))
    .catch(err => console.log(err))
}

export const getProjectDateStatuses = (projectId) => async (dispatch) => {
  await statusesAPI.getProjectDateStatuses(projectId)
    .then(response => dispatch(setDateStatuses(response.data)))
    .catch(err => console.log(err))
}
export const createProjectDateStatus = ({
  id: id,
  name: name,
  color: color
}) => async (dispatch) => {
  await statusesAPI.createProjectDateStatus({
    id: id,
    name: name,
    color: color
  })
    .then(response => dispatch(setCreateProjectDateStatus(response.data)))
    .catch(err => console.log(err))
}
export const deleteProjectDateStatus = (id) => async (dispatch) => {
  await statusesAPI.deleteProjectDateStatus(id)
    .then(response => dispatch(setDeleteProjectDateStatus(id)))
    .catch(err => console.log(err))
}
export const editProjectDateStatus = ({
  id: id,
  name: name,
  color: color
}) => async (dispatch) => {
  await statusesAPI.editProjectDateStatus({
    id: id,
    name: name,
    color: color
  })
    .then(response => dispatch(setEditProjectDateStatus(id, response.data)))
    .catch(err => console.log(err))
}

export const getProjectDateAttributes = (projectId) => async (dispatch) => {
  await statusesAPI.getProjectDateAttributes(projectId)
    .then(response => dispatch(setDateAttributes(response.data)))
    .catch(err => console.log(err))
}
export const createProjectDateAttribute = ({
  id: id,
  name: name,
  color: color
}) => async (dispatch) => {
  await statusesAPI.createProjectDateAttribute({
    id: id,
    name: name,
    color: color
  })
    .then(response => dispatch(setCreateProjectDateAttribute(response.data)))
    .catch(err => console.log(err))
}
export const deleteProjectDateAttribute = (id) => async (dispatch) => {
  await statusesAPI.deleteProjectDateAttribute(id)
    .then(response => dispatch(setDeleteProjectDateAttribute(id)))
    .catch(err => console.log(err))
}
export const editProjectDateAttribute = ({
  id: id,
  name: name,
  color: color
}) => async (dispatch) => {
  await statusesAPI.editProjectDateAttribute({
    id: id,
    name: name,
    color: color
  })
    .then(response => dispatch(setEditProjectDateAttribute(id, response.data)))
    .catch(err => console.log(err))
}
